import React, { useEffect, useState } from "react";
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	Button,
	IconButton,
	Slider,
	Tooltip,
	Dialog,
	DialogTitle,
	DialogActions,
	Select,
	InputLabel,
	MenuItem,
	CircularProgress,
	DialogContent,
	Divider,
} from "@mui/material";
import { nonTechnicalOpeningSchema, technicalOpeningSchema } from "./../../schemas/opening.schema";
import { createOpeningAction, createOpeningSelector } from "../../features/createOpening/createOpeningSlice";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import clsx from "clsx";
import "./../../styles/openingStyles.css";
import { getValuesFromRHFArray } from "../../utilities/utilityFunctions";
import {
	createOpening,
	fetchOpeningById,
	updateOpeningById,
	createOpeningForSuperAdmin,
	updateOpeningByIdForSuperAdmin,
} from "../../utilities/openingsApi";
import { toast } from "react-toastify";
import CreateUpdateOpeningLoader from "../../components/Skeleton-Loaders/CreateUpdateOpeningLoader";
import { availableLanguagesMap } from "../../utilities/constants";
import HelpIcon from "@mui/icons-material/Help";
import MultiLineTextArea from "../../components/MultiLineTextArea";
import { CustomSelectwithController } from "../../components/QuestionBank/Common";
import { useGetCategoriesQuery, useGetQuestionBankQuery } from "../../features/api/apiSlice";
import CustomButton, { TextButton } from "../../components/QuestionBank/Button";
import { ADDCATEGORY, difficultyLevels } from "../QuestionBank";
import RefreshIcon from "@mui/icons-material/Refresh";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const defaultValues = {
	title: "",
	experienceRange: [0, 2],
	proctoring: true,
	isSecondaryCameraRequired: false,
	isCodeEditorRequired: false,
	avatarMode: true,
	questionsBasedOnResume: true,
	isMobileInterviewAllowed: false,
	allowSupportContact: false,
	supportEmail: "",
	supportName: "",
	supportPhone: "",
	emailRecipients: [],
	interviewGuidelines: "",
	customQuestions: [],
	mixOfBothQuestions: "true",
	customFields: [],
	languageOfQuestions: "en",
	languageOfAnswers: "en",
	maxQuestions: 15,
	questionCategoriesFromBank: [],
	autoSkipQTimeoutS: 0,
};

const isValidEmail = (email) => {
	if (email.includes(" ")) {
		toast.warn("Email cannot contain spaces.");
		return false;
	}
	const emailRegex = /^[^@]+@[^@]+\.[^@.]+$/;
	if (!emailRegex.test(email)) {
		return false;
	}
	return true;
};
const ANY_DIFFICULTY = "Any";

const CreateUpdateOpeningWithJD = () => {
	const params = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [status, setStatus] = useState("idle");
	const [isSubmitting, setIsSubmitting] = useState(false);
	const openingTypeFromParams = params.openingType === "0" ? 0 : 1;
	const openingId = params.openingId;
	const isUpdateOpening = Boolean(openingId);
	const newValues = useSelector(createOpeningSelector);
	const user = useSelector((state) => state.auth.user);
	const { organizationId } = useParams();

	useEffect(() => {
		function transform(plainArr) {
			return plainArr.map((value) => {
				return { value: value };
			});
		}
		async function getDataAndDispatch() {
			try {
				setStatus("loading");
				const res = await fetchOpeningById(openingId);
				let {
					skillsGroup: originalSkillsGroups = [],
					coreSkills = [],
					customQuestions = [],
					emailRecipients = [],
					jobRequirementsAndResponsibilities = [],
					minExperience = 0,
					maxExperience,
					mixOfBothQuestions,
					...rest
				} = res;
				if (!!res.isTechnical !== !!openingTypeFromParams) {
					toast.warn(
						"Type of the current opening does not match with the openingType in the url path",
					);
					navigate("/admin");
					return;
				}
				const skillsGroups = originalSkillsGroups.map((skillGroup) => {
					const rhfSkills = transform(skillGroup.skills);
					return { ...skillGroup, skills: rhfSkills };
				});
				coreSkills = transform(coreSkills);
				jobRequirementsAndResponsibilities = transform(jobRequirementsAndResponsibilities);
				emailRecipients = transform(emailRecipients);
				customQuestions = transform(customQuestions);
				setStatus("success");
				dispatch(
					createOpeningAction({
						...rest,
						skillsGroups, // skillsGroup is the one coming from backend, but we are using skillsGroups as the name here (because that makes more sense)
						customQuestions,
						jobRequirementsAndResponsibilities,
						coreSkills,
						emailRecipients,
						experienceRange: [minExperience, maxExperience],
						mixOfBothQuestions: mixOfBothQuestions === true ? "true" : "false",
					}),
				);
			} catch (error) {
				setStatus("error");
				console.log(error);
			}
		}
		if (openingId && isUpdateOpening) {
			getDataAndDispatch();
		}
	}, [openingId, isUpdateOpening, dispatch, navigate, openingTypeFromParams]);

	const methods = useForm({
		defaultValues: defaultValues,
		values: {
			...defaultValues,
			...(openingTypeFromParams === 1 && { isCodeEditorRequired: true }),
			...newValues,
		},
		resolver: zodResolver(
			openingTypeFromParams === 1 ? technicalOpeningSchema : nonTechnicalOpeningSchema,
		),
	});

	const {
		register,
		formState: { errors },
		handleSubmit,
	} = methods;

	const adminUser = isUpdateOpening ? user?.organizationId === newValues?.organizationId : !organizationId;

	const onSubmit = async (data) => {
		const { supportName, supportPhone, supportEmail } = data;

		// Validation logic for support information
		if (
			(supportPhone && !supportName) ||
			(supportEmail && !supportName) ||
			(supportName && !supportPhone && !supportEmail)
		) {
			toast.warn("Please include a support name with the phone number or email or remove all three.");
			return;
		}
		if (params.openingType === "0") {
			// transform data for non tech
			const {
				coreSkills: newCoreSkills,
				jobRequirementsAndResponsibilities: newJobRequirementsAndResponsibilities,
				customQuestions: newCustomQuestions,
				emailRecipients: newEmailRecipeints,
				experienceRange,
			} = data;
			const coreSkills = getValuesFromRHFArray(newCoreSkills);
			const jobRequirementsAndResponsibilities = getValuesFromRHFArray(
				newJobRequirementsAndResponsibilities,
			);
			const customQuestions = getValuesFromRHFArray(newCustomQuestions);
			const emailRecipients = getValuesFromRHFArray(newEmailRecipeints);
			const [minExperience, maxExperience] = experienceRange;
			const finalData = {
				...data,
				coreSkills,
				customQuestions,
				jobRequirementsAndResponsibilities,
				emailRecipients,
				minExperience,
				maxExperience,
				isTechnical: false,
				organizationId: organizationId ?? user?.organizationId,
			};
			isUpdateOpening ? handleUpdateOpening(finalData) : handleCreateOpening(finalData);
		} else if (params.openingType === "1") {
			// transform data for tech
			const {
				skillsGroups: newSkillsGroups,
				customQuestions: newCustomQuestions,
				emailRecipients: newEmailRecipeints,
				jobRequirementsAndResponsibilities: newJobRequirementsAndResponsibilities,
				experienceRange,
			} = data;
			const skillsGroups = newSkillsGroups.map((skillGroup) => {
				const skills = getValuesFromRHFArray(skillGroup.skills);
				return { ...skillGroup, skills };
			});
			const customQuestions = getValuesFromRHFArray(newCustomQuestions);
			const emailRecipients = getValuesFromRHFArray(newEmailRecipeints);
			const jobRequirementsAndResponsibilities = getValuesFromRHFArray(
				newJobRequirementsAndResponsibilities,
			);
			const [minExperience, maxExperience] = experienceRange;
			const finalData = {
				...data,
				customQuestions,
				emailRecipients,
				minExperience,
				maxExperience,
				skillsGroup: skillsGroups, // skillGroup is the name to be sent to the backend
				isTechnical: true,
				jobRequirementsAndResponsibilities,
				organizationId: organizationId ?? user?.organizationId,
			};
			isUpdateOpening ? handleUpdateOpening(finalData) : handleCreateOpening(finalData);
		}
	};

	const handleCreateOpening = async (data) => {
		try {
			setIsSubmitting(true);
			if (adminUser) {
				await createOpening(data);
				navigate("/admin");
			} else {
				await createOpeningForSuperAdmin(data, organizationId);
				navigate(`/superadmin/admin/${organizationId}`);
			}
		} catch (error) {
			toast.warn(error.message);
		} finally {
			setIsSubmitting(false);
		}
	};

	const handleUpdateOpening = async (data) => {
		try {
			setIsSubmitting(true);
			if (adminUser) {
				await updateOpeningById(openingId, data);
				navigate("/admin");
			} else {
				await updateOpeningByIdForSuperAdmin(openingId, organizationId, data);
				navigate(`/superadmin/admin/${organizationId}`);
			}
		} catch (error) {
			toast.warn(error.message);
		} finally {
			setIsSubmitting(false);
		}
	};

	if (!(params.openingType === "0" || params.openingType === "1")) {
		return <Navigate to={"/*"} />;
	}

	if (status === "loading") {
		return <CreateUpdateOpeningLoader />;
	}

	const formType = isUpdateOpening ? "Update" : "Create";
	const formStatus = isUpdateOpening ? "Updating" : "Creating";

	return (
		<div className="create-update-opening flex min-h-screen flex-grow justify-center bg-gray-100 p-6">
			<div className="mx-auto w-[95%] rounded-lg bg-white p-4 shadow-md md:max-w-[75%] lg:max-w-[53%]">
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<h2 className="mb-4 text-xl font-semibold">{formType} Opening</h2>

						<div className="mb-4">
							<label htmlFor="title" className="block text-sm font-medium text-gray-600">
								Job Opening Name
							</label>
							<input
								type="text"
								id="title"
								{...register("title")}
								className="mt-1 w-full rounded-md border p-2"
							/>
							<ErrorMessage errorCriteria={errors?.title?.message} />
						</div>
						<ExperienceRangeSlider />
						<JobRequirementsAndResponsibilitiesRenderer />
						{openingTypeFromParams === 1 && <SkillsGroupsRenderer />}
						{openingTypeFromParams === 0 && <CoreSkillsRenderer />}
						<InterviewSetup openingType={openingTypeFromParams} />
						<WebCamSettings />
						<SupportInformation />
						<Notifications />
						{/* <AutoSkipper /> */}
						<AdditionalSettings />
						<CustomQuestions adminUser={adminUser} />
						<button
							disabled={isSubmitting}
							type="submit"
							className="cursor-pointer rounded border-none bg-blue-500 px-4 py-2 font-bold text-white outline-none hover:bg-blue-700 focus:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
							onClick={handleSubmit(onSubmit)}
						>
							{isSubmitting ? formStatus : formType} Opening
						</button>
					</form>
				</FormProvider>
			</div>
		</div>
	);
};

export default CreateUpdateOpeningWithJD;

const ExperienceRangeSlider = () => {
	const { watch, control } = useFormContext();
	const experienceRange = watch("experienceRange");
	return (
		<div className="mb-4">
			<label htmlFor="experienceRange" className="block text-sm font-medium text-gray-600">
				Experience Range{" "}
				{`(${experienceRange[0]} - ${experienceRange[1]} ${experienceRange[0] === 0 && experienceRange[1] === 1 ? "year" : "years"}) `}
				<Controller
					control={control}
					name="experienceRange"
					render={({ field: { onChange, onBlur, value, ref } }) => {
						return (
							<Slider
								id="experienceRange"
								getAriaLabel={() => "Experience Range for job"}
								value={value}
								valueLabelDisplay="auto"
								onChange={onChange}
								onBlur={onBlur}
								max={15}
								min={0}
								step={1}
								className="text-blue-500"
								valueLabelFormat={(data) => {
									return data === 15 ? "15+" : data;
								}}
							/>
						);
					}}
				/>
			</label>
		</div>
	);
};

// Format time in mm:ss format
const formatTimeMMSS = (seconds) => {
	if (seconds === 0) return "Off";
	const minutes = Math.floor(seconds / 60);
	const remainingSeconds = seconds % 60;
	return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
};

// Custom Slider component with padding
const PaddedSlider = ({ ...props }) => (
	<div className="px-4 py-6">
		<Slider {...props} />
	</div>
);

const AutoSkipTimeoutSlider = () => {
	const { control, watch } = useFormContext();
	const autoSkipQTimeoutS = watch("autoSkipQTimeoutS");

	const handleChange = (event, newValue, onChange) => {
		let adjustedValue = newValue;

		// First step is 30s, then steps of 15s after that
		if (newValue <= 30) {
			adjustedValue = Math.round(newValue / 30) * 30; // Snap to 0 or 30
		} else {
			adjustedValue = Math.round((newValue - 30) / 15) * 15 + 30; // Steps of 15 after 30
		}

		onChange(adjustedValue); // Update with the adjusted value
	};

	return (
		<div className="mb-4">
			<label htmlFor="autoSkipQTimeoutS" className="block text-sm font-medium text-gray-600">
				{/* Show additional note when value is 30 seconds */}
				Auto Skip Timeout (mm:ss): {formatTimeMMSS(autoSkipQTimeoutS)}{" "}
				{autoSkipQTimeoutS === 30 && <span className="text-red-500">(min 45s recommended)</span>}
				<span className="block text-xs font-medium text-gray-600">
					Set the time the AI will wait for a candidate's response before automatically skipping to
					the next question.
				</span>
				<Controller
					control={control}
					name="autoSkipQTimeoutS"
					render={({ field: { onChange, value } }) => (
						<PaddedSlider
							id="autoSkipQTimeoutS"
							getAriaLabel={() => "Auto Skip Timeout"}
							value={value}
							valueLabelDisplay="auto"
							onChange={(_, newValue) => handleChange(_, newValue, onChange)}
							min={0}
							max={120}
							step={1}
							marks={[
								{ value: 0, label: "Off" },
								{ value: 30, label: "00:30" },
								{ value: 45, label: "00:45" },
								{ value: 60, label: "01:00" },
								{ value: 75, label: "01:15" },
								{ value: 90, label: "01:30" },
								{ value: 105, label: "01:45" },
								{ value: 120, label: "02:00" },
							]}
							className="text-blue-500"
							valueLabelFormat={formatTimeMMSS}
						/>
					)}
				/>
			</label>
		</div>
	);
};

const CoreSkillsRenderer = () => {
	const {
		register,
		control,
		formState: { errors },
	} = useFormContext();
	const { fields, append, remove } = useFieldArray({
		control: control,
		name: "coreSkills",
	});
	return (
		<div className="mb-6">
			<div className="flex flex-col gap-3 rounded-lg bg-blue-50 p-4 text-sm shadow-md">
				<h3 className=" text-lg font-semibold">Core Skills</h3>
				{fields.length > 0 && (
					<div className="flex flex-col gap-2">
						{fields.map((field, index) => {
							return (
								<SingleCoreSkill
									key={field.id}
									append={append}
									field={field}
									index={index}
									register={register}
									remove={remove}
									fieldName={"coreSkills"}
									errors={errors}
								/>
							);
						})}
					</div>
				)}
				<button
					type="button"
					className="w-fit cursor-pointer rounded border-none bg-blue-500 px-4 py-2 text-white outline-none hover:bg-blue-700 focus:bg-blue-700"
					onClick={() => {
						append({ value: "" });
					}}
				>
					Add Core skill
				</button>
				<ErrorMessage
					errorCriteria={errors?.coreSkills?.message || errors?.coreSkills?.root?.message}
				/>
			</div>
		</div>
	);
};

const SingleCoreSkill = ({ field, register, index, remove, fieldName, append, errors }) => {
	return (
		<div className="flex flex-col gap-1">
			<div className="flex flex-row items-center gap-5">
				<input
					type="text"
					key={field.id}
					id={field.id}
					onKeyDown={(e) => {
						if (e.key.toLowerCase() === "enter") {
							e.preventDefault();
							append({ value: "" });
						}
					}}
					{...register(`${fieldName}.${index}.value`)}
					className="mt-1 w-full flex-1 rounded-md border p-2"
				/>
				<IconButton
					type="button"
					className="rounded-md"
					onClick={() => {
						remove(index);
					}}
				>
					<DeleteIcon className="text-gray-600" />
				</IconButton>
			</div>
			<ErrorMessage errorCriteria={errors?.[fieldName]?.[index]?.value?.message} />
		</div>
	);
};

const JobRequirementsAndResponsibilitiesRenderer = () => {
	const {
		register,
		control,
		formState: { errors },
	} = useFormContext();
	const { fields, append, remove } = useFieldArray({
		control: control,
		name: "jobRequirementsAndResponsibilities",
	});
	return (
		<div className="mb-6 ">
			<div className="flex flex-col gap-3 rounded-lg bg-gray-50 p-4 text-sm shadow-md">
				<h3 className="text-lg font-semibold">Job Responsibilities</h3>

				{fields.length > 0 && (
					<div className="flex flex-col gap-2">
						{fields.map((field, index) => {
							return (
								<SingleCoreSkill
									key={field.id}
									append={append}
									field={field}
									index={index}
									register={register}
									remove={remove}
									errors={errors}
									fieldName={"jobRequirementsAndResponsibilities"}
								/>
							);
						})}
					</div>
				)}
				<button
					type="button"
					className="w-fit cursor-pointer rounded border-none bg-blue-500 px-4 py-2 text-white outline-none hover:bg-blue-700 focus:bg-blue-700"
					onClick={() => {
						append({ value: "" });
					}}
				>
					Add Job Responsibility
				</button>
				<ErrorMessage
					errorCriteria={
						errors?.jobRequirementsAndResponsibilities?.message ||
						errors?.jobRequirementsAndResponsibilities?.root?.message
					}
				/>
			</div>
		</div>
	);
};

const SkillsGroupsRenderer = () => {
	const {
		register,
		control,
		formState: { errors },
	} = useFormContext();
	const { fields, append, remove } = useFieldArray({
		control: control,
		name: "skillsGroups",
	});
	return (
		<div className="mb-6">
			<div className="rounded-lg bg-blue-50  p-4 text-sm shadow-md">
				<h3 className="mb-2 text-lg font-semibold">Skills to Test</h3>
				<button
					type="button"
					className="mb-2 cursor-pointer rounded border-none bg-blue-500 px-4 py-2 text-white outline-none hover:bg-blue-700 focus:bg-blue-700"
					onClick={() => {
						append({ skillGroupName: "", skills: [{ value: "" }], criteria: 1 });
					}}
				>
					Add Skill Group
				</button>
				<ErrorMessage
					errorCriteria={errors?.skillsGroups?.message || errors?.skillsGroups?.root?.message}
				/>
				<div className="flex flex-col gap-4">
					{fields.map((field, skillGroupIndex) => {
						return (
							<div key={field.id} className="bg-white px-2 py-4 shadow-md md:px-4">
								{" "}
								<SkillGroupName
									skillGroupIndex={skillGroupIndex}
									skillGroup={field}
									register={register}
									errors={errors}
									remove={remove}
								/>
								<SkillsForSkillGroup skillGroupIndex={skillGroupIndex} />
								<ErrorMessage
									errorCriteria={
										errors?.skillsGroups?.[skillGroupIndex]?.skills?.root?.message
									}
								/>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

const SkillsForSkillGroup = ({ skillGroupIndex }) => {
	const {
		register,
		formState: { errors },
		control,
	} = useFormContext();
	const { fields, append, remove } = useFieldArray({
		name: `skillsGroups.${skillGroupIndex}.skills`,
		control: control,
	});
	return (
		<div className="flex flex-col gap-4 p-1">
			{/* <div className="my-2 flex flex-row items-center justify-between"> */}
			<label className="flex w-fit flex-col gap-1  text-sm font-medium text-gray-600">
				Criteria
				<select
					{...register(`skillsGroups.${skillGroupIndex}.criteria`, {
						valueAsNumber: true,
					})}
					className="p-0.5 text-black"
				>
					<option value={1}>All of them are mandatory</option>
					<option value={2}>At least one is mandatory</option>
					<option value={3}>None of them are mandatory</option>
				</select>
			</label>
			{/* </div> */}
			<div className="flex flex-col gap-1">
				{fields.map((skill, index) => {
					return (
						<div key={skill.id} className="flex flex-row items-center justify-between gap-5">
							<div className="flex flex-1 flex-col gap-0">
								<input
									id={skill.id}
									{...register(`skillsGroups.${skillGroupIndex}.skills.${index}.value`)}
									onKeyDown={(e) => {
										if (e.key.toLowerCase() === "enter") {
											e.preventDefault();
											append({ value: "" });
										}
									}}
									placeholder="Enter skill name..."
									className="mt-1 rounded-md border p-2"
								/>
								<ErrorMessage
									errorCriteria={
										errors?.skillsGroups?.[skillGroupIndex]?.skills?.[index]?.value
											?.message
									}
								/>
							</div>

							<IconButton
								type="button"
								className="rounded-md"
								onClick={() => {
									remove(index);
								}}
							>
								<DeleteIcon className="text-gray-600" />
							</IconButton>
						</div>
					);
				})}
			</div>
			<button
				type="button"
				className="w-fit cursor-pointer rounded border-none bg-blue-500 px-4 py-2 text-white outline-none hover:bg-blue-700 focus:bg-blue-700"
				onClick={() => {
					append({
						value: "",
					});
				}}
			>
				Add Skill
			</button>
		</div>
	);
};

const SkillGroupName = ({ skillGroupIndex, skillGroup, register, errors, remove }) => {
	const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
	const handleDeleteDialogclose = () => {
		setConfirmDeleteDialogOpen(false);
	};
	const handleDeleteSkillGroup = () => {
		remove(skillGroupIndex);
	};
	return (
		<>
			<div className="flex flex-col gap-1">
				<div className="flex flex-row items-start justify-between">
					<div className="flex flex-1 flex-col gap-1">
						<label htmlFor={skillGroup.id} className="block text-sm font-medium text-gray-600">
							Skill Group Name
						</label>
						<input
							type="text"
							placeholder="Enter skill group name..."
							id={skillGroup.id}
							onKeyDown={(e) => {
								if (e.key.toLowerCase() === "enter") {
									e.preventDefault();
									// append({ value: "" });
								}
							}}
							{...register(`skillsGroups.${skillGroupIndex}.skillGroupName`)}
							className="mt-1 w-[35%] flex-1 rounded-md border p-2"
						/>
					</div>

					<IconButton
						type="button"
						className="rounded-md"
						onClick={() => {
							setConfirmDeleteDialogOpen(true);
						}}
					>
						<DeleteIcon className="text-gray-600" />
					</IconButton>
				</div>
				<ErrorMessage
					errorCriteria={errors?.skillsGroups?.[skillGroupIndex]?.skillGroupName?.message}
				/>
			</div>
			<Dialog
				open={confirmDeleteDialogOpen}
				onClose={handleDeleteDialogclose}
				aria-labelledby="delete-skill-group-title"
				aria-describedby="delete-skill-group-description"
			>
				<DialogTitle id="delete-skill-group-title">
					{`Are you sure you want to delete the selected skill group?`}
				</DialogTitle>
				<DialogActions>
					<Button onClick={handleDeleteDialogclose}>No</Button>
					<Button onClick={handleDeleteSkillGroup}>Yes</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

const ChipFormInput = ({ tooltip, id, label, dataName }) => {
	const { register, watch, setValue } = useFormContext();
	const value = watch(id);

	const handleKeyDown = (callback) => (e) => {
		if (e.key.toLowerCase() === "enter") {
			callback();
		}
	};

	return (
		<Tooltip arrow title={tooltip}>
			<div
				{...register(id)}
				className={clsx("chip", value ? "active" : "")}
				onClick={() => {
					setValue(id, !value);
				}}
				onKeyDown={handleKeyDown(() => setValue(id, !value))}
				tabIndex={0}
				data-name={dataName}
			>
				{label}
			</div>
		</Tooltip>
	);
};

const InterviewSetup = ({ openingType }) => {
	const { watch, setValue } = useFormContext();
	const isMobileInterviewAllowed = watch("isMobileInterviewAllowed");
	const isSecondaryCameraRequired = watch("isSecondaryCameraRequired");

	useEffect(() => {
		if (isMobileInterviewAllowed) {
			setValue("isSecondaryCameraRequired", false);
		}
	}, [isMobileInterviewAllowed, setValue]);
	return (
		<div className="mb-6">
			<div className="rounded-lg bg-white p-4 text-sm shadow-md">
				<h3 className="mb-2 text-lg font-semibold">Interview Setup</h3>
				{openingType === 1 && (
					<ChipFormInput
						tooltip="Requires a code editor for coding questions"
						id="isCodeEditorRequired"
						label="Code Editor Required"
						dataName="codeEditorRequired"
					/>
				)}
				<ChipFormInput
					tooltip="Utilizes the candidate's resume as a foundation for the interview, with 2-3 tailored questions derived from their resume details. This approach personalizes the interview, focusing on the candidate's specific experiences "
					id="questionsBasedOnResume"
					label="Interview based on Resume"
					dataName="interviewBasedOnResume"
				/>
				<ChipFormInput
					tooltip="Activates a virtual AI avatarMode during the interview, enhancing the interaction with a more engaging and personable experience. Recommended for creating a dynamic and modern interview atmosphere."
					id="avatarMode"
					label="Enable Virtual Avatar"
					dataName="enableVirtualAvatar"
				/>

				{!isSecondaryCameraRequired && (
					<ChipFormInput
						tooltip="Allows candidates to participate in the interview using their mobile device, offering flexibility and convenience. However, for technical roles requiring code submissions, a desktop environment is advised for optimal performance."
						id="isMobileInterviewAllowed"
						label="Allow Interview From Mobile"
						dataName="allowInterviewFromMobile"
					/>
				)}
			</div>
		</div>
	);
};

const WebCamSettings = () => {
	const { watch, setValue } = useFormContext();
	const isSecondaryCameraRequired = watch("isSecondaryCameraRequired");
	const isMobileInterviewAllowed = watch("isMobileInterviewAllowed");

	useEffect(() => {
		if (isSecondaryCameraRequired) {
			setValue("isMobileInterviewAllowed", false);
		}
	}, [isSecondaryCameraRequired, setValue]);

	return (
		<div className="mb-6">
			<div className="rounded-lg bg-white p-4 text-sm shadow-md">
				<h3 className="mb-2 text-lg font-semibold">Web Cam Settings</h3>
				<ChipFormInput
					tooltip="Activates the requirement for candidates to enable their webcam at the start of the interview. This is a necessary step to verify identity and ensure a fair assessment process."
					id="proctoring"
					label="Web Cam Required"
					dataName="webCamRequired"
				/>
				{!isMobileInterviewAllowed && (
					<ChipFormInput
						tooltip="Requires candidates to use a secondary camera via their mobile device by scanning a QR code. This additional camera feed acts as an anti-cheating measure, offering a more comprehensive view to maintain the integrity of the interview process."
						id="isSecondaryCameraRequired"
						label="Secondary Camera Verification"
						dataName="secondaryCameraRequired"
					/>
				)}
			</div>
		</div>
	);
};

const SupportInformation = () => {
	const {
		register,
		formState: { errors },
	} = useFormContext();
	return (
		<div className="mb-6">
			<div className="rounded-lg bg-white p-4 shadow-md">
				<h3 className="mb-2 text-lg font-semibold">Assigned Manager</h3>
				<div className="-mx-2 flex flex-wrap">
					<div className="w-full px-2 sm:w-1/3">
						<label htmlFor="supportName" className="block text-sm font-medium text-gray-600">
							Name
						</label>
						<input
							placeholder="Enter Manager Name"
							type="text"
							id="supportName"
							{...register("supportName")}
							className="mt-1 w-full rounded-md border p-2"
						/>
						<ErrorMessage errorCriteria={errors?.supportName?.message} />
					</div>

					<div className="w-full px-2 sm:w-1/3">
						<label htmlFor="supportPhone" className="block text-sm font-medium text-gray-600">
							Phone Number
						</label>
						<input
							placeholder="+91 9999999999"
							type="text"
							{...register("supportPhone")}
							id="supportPhone"
							className="mt-1 w-full rounded-md border p-2"
						/>
						<ErrorMessage errorCriteria={errors?.supportPhone?.message} />
					</div>

					<div className="w-full px-2 sm:w-1/3">
						<label htmlFor="supportEmail" className="block text-sm font-medium text-gray-600">
							Email ID
						</label>
						<input
							placeholder="Enter Email ID"
							{...register("supportEmail")}
							type="email"
							id="supportEmail"
							className="mt-1 w-full rounded-md border p-2"
						/>
						<ErrorMessage errorCriteria={errors?.supportEmail?.message} />
					</div>
				</div>
				<div className="w-full flex flex-col gap-2 mt-3 select-none">
					<label htmlFor="allowSupportContact" className="block text-sm font-medium text-gray-600">
						Allow candidates to contact?
					</label>
					<div>
						<label className="block text-sm font-medium text-gray-600">
							<input
								{...register("allowSupportContact")}
								type="checkbox"
								className="mr-2 scale-110 align-middle"
								id="allowSupportContact"
							/>
							Yes
						</label>
						<p class="text-xs mt-1 text-gray-500">
							Candidates will see this phone number and can contact you if needed.
						</p>

						<ErrorMessage errorCriteria={errors?.allowSupportContact?.message} />
					</div>
				</div>
			</div>
		</div>
	);
};

const Notifications = () => {
	const {
		control,
		formState: { errors },
	} = useFormContext();
	const { fields, append, remove } = useFieldArray({
		name: "emailRecipients",
		control: control,
	});
	return (
		<div className="mb-6">
			<div className="rounded-lg bg-white p-4 shadow-md">
				<h3 className="mb-2 text-lg font-semibold">Notifications</h3>
				<label htmlFor="notifyEmail" className="mt-4 block text-sm font-medium text-gray-600">
					Email IDs to notify when candidate joins the assessment:
				</label>
				<div
					id="email-chips-container"
					className="mt-1 flex w-full flex-wrap items-center rounded-md border p-2"
				>
					{fields.map((email, index) => {
						return (
							<div
								key={email.id}
								className={clsx(
									"email-chip",
									errors?.emailRecipients?.[index]?.value?.message && "bg-red-300",
								)}
							>
								<span>{email.value}</span>
								<button
									type="button"
									className="remove-email hover:bg-slate-400 focus:bg-slate-400"
									onClick={(e) => {
										remove(index);
									}}
								>
									<i className="fa fa-times scale-75" aria-hidden="true"></i>
								</button>
							</div>
						);
					})}
				</div>
				<input
					type="text"
					id="notifyEmail"
					name="notifyEmail"
					onKeyDown={(e) => {
						if (e.key.toLowerCase() === "enter") {
							e.preventDefault();
							if (isValidEmail(e.target.value)) {
								append({ value: e.target.value });
							} else {
								toast.warn("Please enter a valid email address");
							}
							e.target.value = "";
						}
					}}
					placeholder="Add and press enter"
					className="mt-1 w-full rounded-md border p-2"
				/>
			</div>
		</div>
	);
};

// const AutoSkipper = () => {
// 	const {
// 		register,
// 		formState: { errors },
// 	} = useFormContext();
// 	return (
// 		<div className="mb-6">
// 			<div className="rounded-lg bg-white p-4 shadow-md">
// 				<div className="mb-6">
// 					<label htmlFor="autoSkipQTimeoutS" className="block text-sm font-medium text-gray-600">
// 						Auto Skip Timeout in seconds (0 to turn off, or 30-120 seconds)
// 					</label>
// 					<input
// 						type="number"
// 						id="autoSkipQTimeoutS"
// 						min={0}
// 						max={120}
// 						{...register("autoSkipQTimeoutS", {
// 							valueAsNumber: true,
// 						})}
// 						className="mt-1 w-full rounded-md border p-2"
// 					/>
// 					<ErrorMessage errorCriteria={errors?.autoSkipQTimeoutS?.message} />
// 				</div>
// 			</div>
// 		</div>
// 	);
// };

const minQuestions = 5;
const maxQuestions = 18;

const AdditionalSettings = () => {
	const {
		control,
		register,
		formState: { errors },
	} = useFormContext();
	return (
		<div className="mb-6">
			<div className="rounded-lg bg-white p-4 shadow-md">
				<h3 className="mb-2 text-lg font-semibold">Additional Settings</h3>
				<div className="mb-6">
					<label htmlFor="maxQuestions" className="block text-sm font-medium text-gray-600">
						Max Number of Questions to Ask
					</label>
					<input
						type="number"
						id="maxQuestions"
						min={minQuestions}
						max={maxQuestions}
						{...register("maxQuestions", {
							valueAsNumber: true,
						})}
						className="mt-1 w-full rounded-md border p-2"
					/>
					<ErrorMessage errorCriteria={errors?.maxQuestions?.message} />
				</div>
				<AutoSkipTimeoutSlider />
				<LanguageComponent
					label={"Select the language in which AI should ask the questions:"}
					id={"languageOfQuestions"}
					control={control}
				/>

				<LanguageComponent
					label={"Select the language in which the candidate can answer the questions:"}
					id={"languageOfAnswers"}
					control={control}
					additionalInfo={
						"If a language other than English is selected, the candidate can answer either in that language or in English."
					}
				/>

				<div className="mb-6">
					<label
						htmlFor="interviewGuidelines"
						className="info-icon block text-sm font-medium text-gray-600"
					>
						Special Instructions &nbsp;
						<Tooltip
							title="Use this field to input any additional guidelines or focus areas for the AI when crafting questions. For example, you can specify the industry domain relevant to the job position, or highlight particular aspects of the job description that should be emphasized. This information helps tailor the AI's questioning to better align with the job's specific requirements and the candidate's background. It ensures a more targeted and relevant assessment by directing the AI to consider these special instructions alongside the skills and experience levels mentioned in the candidate's form."
							arrow={true}
						>
							<i className="fas fa-info-circle"></i>
						</Tooltip>
					</label>
					<textarea
						id="interviewGuidelines"
						rows="8"
						{...register("interviewGuidelines")}
						className="mt-1 w-full resize-none resize-y whitespace-pre-line rounded-md border p-2 text-sm"
					></textarea>
				</div>
			</div>
		</div>
	);
};

const CustomQuestions = ({ adminUser }) => {
	const {
		formState: { errors },
		register,
		control,
	} = useFormContext();
	const { fields, append, remove } = useFieldArray({
		name: "customQuestions",
		control: control,
	});
	const {
		fields: questionCategoriesFromBankFields,
		append: questionCategoriesFromBankAppend,
		remove: questionCategoriesFromBankRemove,
	} = useFieldArray({
		control,
		name: "questionCategoriesFromBank",
	});
	const [isSelectQuestionsFromQBModalOpen, setIsSelectQuestionsFromQBModalOpen] = useState(false);

	const close = () => {
		setIsSelectQuestionsFromQBModalOpen(false);
	};

	return (
		<div className="mb-6 ">
			<div className="flex flex-col gap-3 rounded-lg bg-white p-4 shadow-md">
				<h3 className=" text-lg font-semibold">Custom Questions</h3>
				{(fields.length > 0 || questionCategoriesFromBankFields.length > 0) && (
					<div className="flex flex-col gap-2 text-sm">
						<label className="font-medium text-gray-600">
							<>Ask a mix of questions from ai and this list?</>
						</label>
						<div className="flex flex-col gap-2 md:flex-row md:items-center md:gap-5">
							<label className="flex flex-row gap-2  md:justify-center">
								<input
									{...register("mixOfBothQuestions")}
									type="radio"
									name="mixOfBothQuestions"
									value={"true"}
									className="scale-125"
								/>
								<Tooltip
									title="Allows a variety of questions, including both admin-specified and AI-generated ones."
									arrow
								>
									<>Mix of these questions and AI's questions</>
								</Tooltip>
							</label>
							<label className="flex flex-row gap-2 md:justify-center">
								<input
									{...register("mixOfBothQuestions")}
									type="radio"
									className="scale-125"
									name="mixOfBothQuestions"
									value={"false"}
								/>
								<Tooltip
									title="Restricts questions to only those predefined or specified here."
									arrow
								>
									<>Only these questions</>
								</Tooltip>
							</label>
						</div>
					</div>
				)}
				<div className="flex flex-col gap-1">
					{fields.map((question, index) => {
						return (
							<EachCustomQuestion
								key={question.id}
								question={question}
								register={register}
								customQuestionIndex={index}
								remove={remove}
								append={append}
								errors={errors}
								control={control}
							/>
						);
					})}
				</div>

				<div
					className={`grid grid-cols-1 ${fields.length > 0 ? "md:grid-cols-4" : "md:grid-cols-3"} place-content-center gap-3`}
				>
					<Button2
						onClick={() => {
							append({
								value: "",
							});
						}}
					>
						Add Question
					</Button2>
					<Button2
						onClick={() => {
							questionCategoriesFromBankAppend({
								categoryId: "",
								difficulty: ANY_DIFFICULTY,
								numberOfQuestions: 1,
							});
						}}
						disabled={!adminUser}
						title={"Add categories from the question bank to ask random questions"}
					>
						Add questions from Q Bank
					</Button2>
					<Button2
						disabled={!adminUser}
						onClick={() => {
							setIsSelectQuestionsFromQBModalOpen(true);
						}}
					>
						Select Specific Questions from Bank
					</Button2>
					{fields.length > 0 && (
						<Link
							to={"/admin/create-markdown-tables"}
							target="_blank"
							className="text-blue-500 my-auto  flex flex-row items-center gap-1  text-sm no-underline hover:text-blue-700"
						>
							generate markdown <OpenInNewIcon fontSize="10px" />
						</Link>
					)}
				</div>
				{adminUser ? (
					<RandomQuestionsFromBank
						fields={questionCategoriesFromBankFields}
						append={questionCategoriesFromBankAppend}
						remove={questionCategoriesFromBankRemove}
					/>
				) : null}
				{adminUser && isSelectQuestionsFromQBModalOpen ? (
					<SelectQuestionsFromBankModal
						customQuestionsAppend={append}
						open={isSelectQuestionsFromQBModalOpen}
						handleClose={close}
					/>
				) : null}

				<ErrorMessage
					errorCriteria={errors?.customQuestions?.message || errors?.customQuestions?.root?.message}
				/>
			</div>
		</div>
	);
};

const EachCustomQuestion = ({ question, register, customQuestionIndex, remove, append, errors, control }) => {
	return (
		<div key={question.id} className="flex flex-row items-center justify-between gap-5">
			<div className="flex flex-1 flex-col gap-0">
				<MultiLineTextArea
					registeredName={`customQuestions.${customQuestionIndex}.value`}
					id={question.id}
					placeholder={"Enter a custom question..."}
					control={control}
				/>
				<ErrorMessage
					errorCriteria={errors?.customQuestions?.[customQuestionIndex]?.value?.message}
				/>
			</div>

			<IconButton
				type="button"
				className="rounded-md"
				onClick={() => {
					remove(customQuestionIndex);
				}}
			>
				<DeleteIcon className="text-gray-600" />
			</IconButton>
		</div>
	);
};

const LanguageComponent = ({ id, label, additionalInfo, control }) => {
	return (
		<div className="mb-6">
			<div className="flex flex-row justify-start items-center flex-wrap gap-2">
				<InputLabel
					className="whitespace-normal text-sm font-medium text-gray-600"
					id={`${id}-label`}
				>
					{label} &nbsp;
				</InputLabel>
				<Controller
					control={control}
					name={id}
					render={({ field: { value, onChange } }) => {
						return (
							<Select
								labelId={`${id}-label`}
								id={id}
								value={value}
								onChange={onChange}
								sx={{
									minWidth: "130px",
									"& .MuiOutlinedInput-notchedOutline": {
										border: "1px solid rgb(229, 231, 235)",
									},
									"& .MuiSelect-select": {
										padding: "3px 5px",
									},
									"&:hover .MuiOutlinedInput-notchedOutline": {
										border: "1px solid rgb(229, 231, 235)",
									},
								}}
								MenuProps={{
									PaperProps: {
										sx: {
											borderRadius: "5px",
											boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.2)",
										},
									},
								}}
								className="rounded-md text-sm"
							>
								{Object.entries(availableLanguagesMap).map(([key, value]) => {
									return (
										<MenuItem
											key={value}
											value={key}
											className="border-none outline-none text-sm"
										>
											{value}
										</MenuItem>
									);
								})}
							</Select>
						);
					}}
				/>
				{additionalInfo ? (
					<Tooltip title={additionalInfo}>
						<HelpIcon />
					</Tooltip>
				) : null}
			</div>
		</div>
	);
};

export const ErrorMessage = ({ errorCriteria }) => {
	return <>{errorCriteria ? <p className="text-sm text-red-400">{errorCriteria}</p> : <p></p>}</>;
};

const Button2 = ({ type = "button", onClick, children, title = "", disabled = false }) => {
	return (
		<button
			type={type}
			{...(title && { title: title })}
			disabled={disabled}
			className="cursor-pointer rounded border-none bg-blue-500 px-4 py-2 text-white outline-none hover:bg-blue-700 focus-visible:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-blue-500"
			onClick={onClick}
		>
			{children}
		</button>
	);
};

const RandomQuestionsFromBank = ({ fields, append, remove }) => {
	const { data, isLoading, isSuccess } = useGetCategoriesQuery();
	const {
		control,
		register,
		formState: { errors },
	} = useFormContext();

	if (isLoading) {
		return <CircularLoader />;
	}
	if (data && isSuccess && fields.length > 0) {
		return (
			<div className="mb-4 p-4 bg-gray-50 rounded-md shadow-md" id="questionBankSection">
				<h3 className="text-sm font-medium text-gray-600 mb-4">Question Bank</h3>
				{fields.map((field, index) => {
					return (
						<div
							key={field.id}
							className="grid grid-cols-1 md:grid-cols-3 place-content-center gap-2 mb-3 border-[1px] relative border-slate-200 p-3 rounded-md border-solid category-parent"
						>
							<IconButton className="absolute top-0 right-0" onClick={() => remove(index)}>
								<i
									className="fa fa-trash category-child text-xs hover:text-red-500"
									aria-hidden="true"
								></i>
							</IconButton>
							<CustomSelectwithController
								control={control}
								name={`questionCategoriesFromBank.${index}.categoryId`}
								getItemsJSX={() => {
									return data.categories.map((item) => {
										const { _id, categoryName } = item;
										return (
											<MenuItem
												key={_id}
												value={_id}
												className="border-none outline-none text-sm"
											>
												{categoryName}
											</MenuItem>
										);
									});
								}}
								id={`select-category-random-cid-${index}`}
								label={"Category"}
								error={errors?.questionCategoriesFromBank?.[index]?.categoryId?.message}
							/>
							{/* add errormessage later */}
							<CustomSelectwithController
								control={control}
								name={`questionCategoriesFromBank.${index}.difficulty`}
								getItemsJSX={() => {
									return [ANY_DIFFICULTY, ...difficultyLevels].map((item) => {
										return (
											<MenuItem
												key={item}
												value={item}
												className="border-none outline-none text-sm"
											>
												{item}
											</MenuItem>
										);
									});
								}}
								id={`select-category-random-difficulty-${index}`}
								label={"Difficulty"}
								error={errors?.questionCategoriesFromBank?.[index]?.difficulty}
							/>
							<div>
								<label
									htmlFor={`random-questions-${index}-number`}
									className="whitespace-normal block text-gray-700 text-xs font-bold"
								>
									Number of Questions
								</label>
								<input
									{...register(`questionCategoriesFromBank.${index}.numberOfQuestions`, {
										valueAsNumber: true,
									})}
									min={1}
									max={18}
									type="number"
									id={`random-questions-${index}-number`}
									className="mt-1 w-full rounded-md border p-2 invalid:text-red-500 invalid:border-red-500"
								/>
							</div>
						</div>
					);
				})}
				<ErrorMessage
					errorCriteria={
						errors?.questionCategoriesFromBank?.message ||
						errors?.questionCategoriesFromBank?.root?.message
					}
				/>
			</div>
		);
	}
};

const SelectQuestionsFromBankModal = ({ open, handleClose, customQuestionsAppend }) => {
	const [categoryFilterType, setCategoryFilterType] = useState("All");
	const [selectedSet, setSelectedSet] = useState(new Set());
	const { data, isSuccess, refetch, isLoading: isQbLoading } = useGetQuestionBankQuery();
	const { refetch: categoriesRefetch, isCategoriesLoading } = useGetCategoriesQuery();

	const handleChangeSetItems = (e, qid, cid) => {
		const tempSet = new Set(selectedSet);
		if (e.target.checked) {
			// add to set
			tempSet.add(qid);
			setSelectedSet(tempSet);
		} else {
			// remove from set
			tempSet.delete(qid);
			setSelectedSet(tempSet);
		}
	};
	const handleAddSelectedQuestionsToCustomQuestionsList = () => {
		if (isSuccess && data) {
			const { questionBank } = data;
			const finalQuestionsToAdd = questionBank
				.filter((category) => selectedSet.has(category?.questions?._id))
				.map((filteredCategory) => ({ value: filteredCategory?.questions?.question }));

			customQuestionsAppend(finalQuestionsToAdd);
		}
		handleReset();
		handleClose();
	};

	const handleReset = () => {
		setSelectedSet(new Set());
	};

	const handleDialogClose = (e) => {
		handleReset();
		handleClose();
	};

	const handleCategoryChange = (cid) => {
		setCategoryFilterType(cid);
	};

	const handleRefetchQuestionBank = (e) => {
		refetch();
		categoriesRefetch();
	};

	return (
		<>
			<Dialog
				fullWidth={true}
				maxWidth={"lg"}
				open={open}
				sx={{
					"& .MuiBackdrop-root": {
						backgroundColor: "rgba(0, 0, 0, 0.4	)",
					},
				}}
				className="select-questions-from-bank-form text-sm "
				scroll="paper"
				onClose={handleDialogClose}
				PaperProps={{
					elevation: 1,
					sx: {
						borderRadius: "0.5rem",
						padding: "0.8rem",
					},
				}}
			>
				<DialogTitle className="text-2xl font-semibold text-black pb-0">
					Select Specific Questions from Bank
				</DialogTitle>
				<DialogContent className=" flex max-h-96  flex-col gap-1 overflow-y-auto bg-white py-3">
					<div className="flex flex-col gap-5 py-3 px-2">
						<SelectCategories
							handleCategoryChange={handleCategoryChange}
							categoryFilterType={categoryFilterType}
							selectedSet={selectedSet}
						/>
						<div className="flex flex-row gap-1 justify-center items-center">
							<Divider className="flex-grow-[1]" />
							{!isQbLoading && !isCategoriesLoading ? (
								<TextButton
									colorVariant={"text-blue"}
									className="flex-shrink-0"
									title="Refetch the question bank and categories"
									onClick={handleRefetchQuestionBank}
								>
									<RefreshIcon className="size-6 text-blue-500 m-0 p-0" />
								</TextButton>
							) : (
								""
							)}
						</div>
						<QuestionsFromBankByCategory
							categoryFilterType={categoryFilterType}
							selectedSet={selectedSet}
							handleChangeSetItems={handleChangeSetItems}
						/>
					</div>
				</DialogContent>
				<DialogActions className="w-full border-gray-300 bg-gray-50 px-5 py-3">
					<CustomButton colorVariant={"red"} onClick={handleDialogClose}>
						Cancel
					</CustomButton>
					<Button2 type="submit" onClick={handleAddSelectedQuestionsToCustomQuestionsList}>
						Add Selected Questions
					</Button2>
				</DialogActions>
			</Dialog>
		</>
	);
};

const SelectCategories = ({ handleCategoryChange, categoryFilterType, selectedSet }) => {
	const { isLoading, isSuccess, data, isFetching } = useGetCategoriesQuery();
	const { data: qbData, isSuccess: IsQBSuccess } = useGetQuestionBankQuery();

	if (isLoading) {
		return <CircularLoader />;
	}
	if (isSuccess && IsQBSuccess) {
		const { categories } = data;
		const { questionBank } = qbData;
		const categoriesSelected = questionBank.reduce((acc, questionObj) => {
			const {
				_id: cid,
				questions: { _id: qid },
			} = questionObj;
			if (selectedSet.has(qid)) {
				const newSet = new Set(acc);
				newSet.add(cid);
				return newSet;
			} else {
				return acc;
			}
		}, new Set());

		return (
			<div className="flex flex-row flex-wrap gap-2 items-center content-center">
				{categories.map((category) => {
					const { _id: id, categoryName } = category;
					return (
						<CustomButton
							key={id}
							colorVariant={"gray"}
							selected={categoryFilterType === id}
							disabled={isFetching}
							onClick={() => handleCategoryChange(id)}
							highlighted={categoriesSelected.has(id)}
						>
							{categoryName}
						</CustomButton>
					);
				})}
				<Link
					to={`/admin/question-bank?modalType=${ADDCATEGORY}`}
					className="no-underline text-blue-500 py-2 text-xs"
					target="_blank"
					rel="noopener noreferrer"
				>
					+ Add Category
				</Link>
			</div>
		);
	}
};

const QuestionsFromBankByCategory = ({ selectedSet, handleChangeSetItems, categoryFilterType }) => {
	const { data, isLoading, isSuccess, isFetching } = useGetQuestionBankQuery();
	if (isLoading) {
		return (
			<div className="flex justify-center items-center w-full">
				<CircularProgress />
			</div>
		);
	}
	if (isSuccess) {
		const { questionBank } = data;
		const filteredQuestionBank =
			categoryFilterType === "All"
				? questionBank
				: questionBank.filter((category) => category._id === categoryFilterType);

		return (
			<div className="flex flex-col gap-3">
				{filteredQuestionBank.map((questionObj, index) => {
					const {
						_id: cid,
						questions: { _id: qid, question },
					} = questionObj;
					return (
						<label
							className={clsx(
								"cursor-pointer text-sm font-normal text-gray-600 flex flex-row justify-start items-center gap-3",
								isFetching && "opacity-50",
							)}
							key={qid}
						>
							<input
								type="checkbox"
								className="scale-150 flex-shrink-0 text-blue-600 cursor-pointer accent-blue-500 disabled:opacity-50"
								name={`question-${qid}`}
								checked={selectedSet.has(qid)}
								onChange={(e) => handleChangeSetItems(e, qid, cid)}
								disabled={isFetching}
							/>
							{question}
						</label>
					);
				})}
			</div>
		);
	}
};

const CircularLoader = () => {
	return (
		<div className="py-6 flex justify-center items-center min-h-[90vh]  w-full">
			<CircularProgress />
		</div>
	);
};
