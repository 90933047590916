import React from "react";
import AddEditCandidateModal from "../../AddEditCandidates";
import { IconButton, Tooltip } from "@mui/material";
import { Edit } from "@mui/icons-material";

const CandidateDetailsRenderer = ({ params, openingData }) => {
	const candidateToEdit = params.row;
	const [openModal, setOpenModal] = React.useState(false);

	return (
		<div className="flex align-middle h-full w-full items-center">
			<AddEditCandidateModal
				open={openModal}
				onClose={() => setOpenModal(false)}
				openingId={openingData?._id}
				isEditing={true}
				candidate={{
					firstName: candidateToEdit.firstName || "",
					lastName: candidateToEdit.lastName || "",
					preferredName: candidateToEdit.preferredName || "",
					email: candidateToEdit.email || "",
					phoneNumber: candidateToEdit.phoneNumber || "",
					experience: candidateToEdit.experience || (candidateToEdit.experience === 0 ? "0" : ""),
					resumeFileNameInS3: candidateToEdit.resumeFileNameInS3 || "",
				}}
				finalCustomFields={openingData?.customFields?.filter((field) => !field.isDeleted)}
				isResumeRequired={openingData?.questionsBasedOnResume}
				candidateId={candidateToEdit._id}
			/>
			<div>
				<p className="text-sm font-semibold">
					{params.row.firstName} {params.row.lastName}
				</p>
				<p className="text-sm text-gray-500 relative w-min flex align-middle items-center">
					{params.row?.phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") || "N/A"}
					<Tooltip title="Edit Candidate" placement="top" arrow>
						<IconButton
							className="absolute edit-icon-for-rows -right-10 bg-sky-100 scale-[.6] cursor-pointer"
							onClick={() => setOpenModal(true)}
						>
							<Edit color="primary" />
						</IconButton>
					</Tooltip>
				</p>
				<p className="text-sm text-blue-500">{params.row.email}</p>
			</div>
		</div>
	);
};

export default CandidateDetailsRenderer;