import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ContentLoader from "react-content-loader";
import "./AdminPanelStyles.css";
import {
	Button,
	Box,
	Typography,
	Dialog,
	DialogActions,
	DialogTitle,
	DialogContent,
	LinearProgress,
	DialogContentText,
	Collapse,
	Switch,
	ThemeProvider,
	useTheme,
	FormGroup,
	FormControlLabel,
	Tooltip,
	Modal,
} from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
	deleteOpeningById,
	fetchOpenings2,
	starOpening,
	updateOpeningById,
	duplicateOpeningById,
} from "../utilities/openingsApi";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { logoutUser } from "../features/auth/authSlice";
import { checkIfInvalidOrUnauthorized } from "./../utilities/utilityFunctions";
import { isMobile } from "react-device-detect";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useCollapseExpand from "../custom-hooks/useCollapseExpand";
import clsx from "clsx";
import AssignedManager from "../components/AdminPanel/AssignedManager";

function AdminPanel() {
	const theme = useTheme();
	const { user } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [openings, setOpenings] = useState([]);
	const [isFetchingOpenings, setIsFetchingOpenings] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [toBeDeletedId, setToBeDeletedId] = useState(null);
	const [searchOpening, setSearchOpening] = useState("");
	const searchOpeningRef = useRef(null);
	const [starredOpenings, setStarredOpenings] = useState([]);
	const [copied, setCopied] = useState({});
	const [openForCards, setOpenForCards] = useState({});
	const [openForStarredCards, setOpenForStarredCards] = useState({});
	const [colorSchemes, setColorSchemes] = useState([]);
	const [starSpinner, setStarSpinner] = useState([]);
	const [openingStatus, setOpeningStatus] = useState([]);
	const [openingStatusSpinner, setOpeningStatusSpinner] = useState([]);
	const { organizationId } = useParams();
	const location = useLocation();
	const [showRestrictedModal, setShowRestrictedModal] = useState(false);
	const [restrictedModalText, setRestrictedModalText] = useState("");
	const [restricted, setRestricted] = useState(false);
	const userEmail = user?.email;
	const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
	const [toBeDuplicateId, setToBeDuplicateId] = useState(null);
	const [isDuplicating, setIsDuplicating] = useState(false);

	// Modal Style
	const modalStyle = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		bgcolor: "background.paper",
		boxShadow: 24,
		p: 4,
		overflowY: "auto", // Optional, for scrolling if the form is too long
		maxHeight: "90vh", // Optional, adjust as needed
		borderRadius: "10px",
	};

	let organizationName = location?.state?.organizationName;

	async function getOpenings() {
		try {
			if (user) {
				setIsFetchingOpenings(true);
				setStarredOpenings(user?.StarredOpenings);
				setRestricted(user?.restricted);
				const response2 = await fetchOpenings2(organizationId);
				const openingsWithColors = response2.openings;
				if (openingsWithColors && Array.isArray(openingsWithColors)) {
					const starredOpeningsTemp = user?.StarredOpenings;
					// Sort the openings with starred ones first
					const sortedOpenings = openingsWithColors.sort((a, b) => {
						const aIsStarred = starredOpeningsTemp.includes(a._id) ? -1 : 0;
						const bIsStarred = starredOpeningsTemp.includes(b._id) ? -1 : 0;
						return aIsStarred - bIsStarred || new Date(b.createdAt) - new Date(a.createdAt);
					});
					openingsWithColors.forEach((opening) => {
						setOpeningStatus((prev) => ({
							...prev,
							[opening._id]: opening.status,
						}));
					});
					setOpenings(sortedOpenings);
				}
				setIsFetchingOpenings(false);
			}
		} catch (e) {
			console.log(e);
		}
	}

	useEffect(() => {
		async function methodCalls() {
			await getOpenings();
		}
		if (user) {
			methodCalls();
		}
		window.scrollTo(0, 0);
	}, [user]);

	useEffect(() => {
		const focusOpeningSearchBar = (e) => {
			if ((e.ctrlKey || e.metaKey) && e.key?.toLowerCase() === "k") {
				e.preventDefault();
				const currentRef = searchOpeningRef?.current;
				currentRef?.focus();
			}
		};
		const removeFocusOnSearchBar = (e) => {
			if (e.key?.toLowerCase() === "escape") {
				const currentRef = searchOpeningRef?.current;
				currentRef?.blur();
			}
		};

		const searchOpeningBoxRef = searchOpeningRef?.current;
		window.addEventListener("keydown", focusOpeningSearchBar);
		if (searchOpeningBoxRef) {
			searchOpeningBoxRef?.addEventListener("keydown", removeFocusOnSearchBar);
		}
		return () => {
			window.removeEventListener("keydown", focusOpeningSearchBar);
			if (searchOpeningBoxRef) {
				searchOpeningBoxRef?.removeEventListener("keydown", removeFocusOnSearchBar);
			}
		};
	}, [searchOpeningRef]);

	async function handleDeleteOpeningBtnClick() {
		try {
			if (toBeDeletedId) {
				setIsDeleting(true);
				const response = await deleteOpeningById(toBeDeletedId);
				// starredOpenings[toBeDeletedId] &&
				// 	(await Star(toBeDeletedId, 'notoast'));
				starredOpenings.includes(toBeDeletedId) && (await Star(toBeDeletedId, "notoast"));
				toast.success(
					`Opening ${
						openings.find((opening) => opening._id === toBeDeletedId) &&
						openings.find((opening) => opening._id === toBeDeletedId).title
					} deleted`,
				);
				setIsDeleting(false);
				setDeleteDialogOpen(false);
				setToBeDeletedId(null);
				getOpenings();
			}
		} catch (e) {
			setIsDeleting(false);
			setDeleteDialogOpen(false);
			setToBeDeletedId(null);
			toast.warn(e.message);
			if (checkIfInvalidOrUnauthorized(e.message)) {
				dispatch(logoutUser());
			}
		}
	}

	async function handleCopyInterviewLink(openingId) {
		try {
			await navigator.clipboard.writeText(`${window.origin}/interview/${openingId}`);
			setCopied((prev) => ({
				...prev,
				[openingId]: true,
			}));
			toast.success("Interview link copied to clipboard");
		} catch (e) {
			console.log("Failed to copy message to clipboard");
			console.log(e.message);
		}
	}

	useEffect(() => {
		let timeoutID;
		try {
			if (Object.keys(copied).length > 0) {
				timeoutID = setTimeout(() => {
					setCopied({});
				}, 3000);
			}
		} catch (e) {
			console.log(e);
		}
		return () => {
			clearTimeout(timeoutID);
		};
	}, [copied]);

	const handleDeleteDialogclose = () => {
		setDeleteDialogOpen(false);
		setToBeDeletedId(null);
	};

	const Star = async (openingId, title) => {
		setStarSpinner((prev) => ({
			...prev,
			[openingId]: true,
		}));
		try {
			const data = await starOpening({ userEmail, openingId });
			if (data) {
				if (data.starred) {
					setStarredOpenings((prev) => [...prev, openingId]);
				} else {
					title !== "notoast" &&
						setStarredOpenings((prev) => {
							return prev.filter((id) => id !== openingId);
						});
				}
			} else {
				toast.error("Failed to star");
			}
		} catch (error) {
			console.log(error);
		} finally {
			setStarSpinner((prev) => ({
				...prev,
				[openingId]: false,
			}));
		}
	};

	const handleDuplicateDialogClose = () => {
		setToBeDuplicateId(null);
		setDuplicateDialogOpen(false);
	};

	async function handleDuplicateOpeningBtnClick() {
		try {
			if (toBeDuplicateId) {
				setIsDuplicating(true);
				const response = await duplicateOpeningById(toBeDuplicateId);
				// starredOpenings.includes(toBeDuplicateId) && (await Star(toBeDuplicateId, "notoast"));
				toast.success("Duplicate Opening Created Successfully");
				window.scrollTo(0, 0);
				if (!organizationId)
					navigate(
						`/admin/create-opening-from-jd/update/${response?.isTechnical ? "1" : "0"}/${response?.duplicateOpeningId}`,
					);
				else {
					navigate(
						`create-opening-from-jd/update/${response?.isTechnical ? "1" : "0"}/${response?.duplicateOpeningId}`,
						1,
					);
				}
				// getOpenings();
			}
		} catch (e) {
			setIsDuplicating(false);
			setDuplicateDialogOpen(false);
			setToBeDuplicateId(null);
			toast.warn(`${e?.message}`);
		}
	}
	const OpeningCards = ({ opening, index }) => {
		return (
			<Box
				className={`bg-white p-6 rounded-lg shadow-md card relative flex flex-col justify-between h-full ${
					toBeDeletedId === opening._id ? "bg-red-100" : ""
				}`}
				key={opening._id}
			>
				<Box className="flex flex-col justify-between">
					{starSpinner[opening._id] ? (
						<i id="" className="fas fa-circle-notch fa-spin text-lg absolute top-2 right-2"></i>
					) : (
						<i
							className={`fas fa-star text-lg absolute top-2 right-2 cursor-pointer ${
								starredOpenings.includes(opening._id) ? "text-yellow-400" : "text-gray-200"
							}`}
							onClick={(e) => {
								e.stopPropagation();
								Star(opening._id, opening.title);
							}}
						></i>
					)}
					<Typography
						variant="p"
						className="text-gray-500 font-bold text-xs mb-2 cursor-pointer"
						onClick={(e) => {
							// setOpenForCards((prev) => ({
							// 	...prev,
							// 	[opening._id]: !prev[opening._id],
							// }));
							e.stopPropagation();
							window.scrollTo(0, 0);
							navigate(`/admin/evaluations/${opening._id}`);
						}}
					>
						{/* Job ID: {opening._id.slice(0, 5) + '...'} */}
						Job ID: {opening._id}
					</Typography>
					<Typography
						variant="h3"
						className="text-lg font-semibold mb-2 cursor-pointer"
						onClick={(e) => {
							// setOpenForCards((prev) => ({
							// 	...prev,
							// 	[opening._id]: !prev[opening._id],
							// }));
							e.stopPropagation();
							window.scrollTo(0, 0);
							navigate(`/admin/evaluations/${opening._id}`);
						}}
					>
						{opening.title}
					</Typography>
					<Typography variant="p" className="text-gray-600 font-semibold mb-2 text-xs">
						Experience Level:{" "}
						{"minExperience" in opening && "maxExperience" in opening
							? `${opening.minExperience}-${opening.maxExperience} years`
							: `${opening?.experience} years`}
					</Typography>
					<Box className="text-gray-500 text-xs mb-2">
						{/* Created on: Jan 20, 2024 {opening.createdAt} */}
						{`Created on: ${new Date(opening.createdAt).toLocaleString("default", {
							month: "short",
						})} ${new Date(opening.createdAt).getDate()}, ${new Date(
							opening.createdAt,
						).getFullYear()}`}
					</Box>
					<Collapse in={openForCards[opening._id]} timeout={"auto"} unmountOnExit>
						<Box className="flex flex-row justify-start items-center gap-4">
							<RenderSpecialInstructions specialInstructions={opening.interviewGuidelines} />
						</Box>
					</Collapse>
					{!opening.isTechnical && opening?.coreSkills?.length > 0 ? (
						<RenderCoreSkills
							coreSkills={opening.coreSkills}
							colorSchemes={opening.colorSchemes}
						/>
					) : (
						<RenderSkillGroup
							skillsGroup={opening.skillsGroup}
							colorSchemes={opening.colorSchemes}
						/>
					)}
				</Box>
				<Box className="mt-4">
					{openingStatus[opening._id] && (
						<Box className="flex flex-col items-start mb-2">
							<label htmlFor={opening._id} className="text-xs text-gray-800">
								Interview URL:
							</label>
							<Box className="flex items-center">
								<input
									type="text"
									id={opening._id}
									className="border rounded-md p-2 w-44 text-xs bg-gray-50"
									value={`${window.location.protocol}//${window.location.host}/interview/${opening._id}`}
									disabled
								/>
								{copied[opening._id] ? (
									<i className="fas fa-check text-green-500 text-sm ml-2"></i>
								) : (
									<i
										className="fas fa-copy text-gray-500 text-sm cursor-pointer ml-2"
										onClick={(e) => {
											e.stopPropagation();
											handleCopyInterviewLink(opening._id);
										}}
									></i>
								)}
							</Box>
						</Box>
					)}
					<ShowCandidatesButton
						restricted={restricted}
						setShowRestrictedModal={setShowRestrictedModal}
						setRestrictedModalText={setRestrictedModalText}
						restrictedModalText={user.canConductInterviewMessage}
						organizationId={organizationId}
						openingId={opening._id}
						openingStatus={openingStatus[opening._id]}
						interviewReportsCount={opening?.interviewReportsCount}
						navigate={navigate}
						hasSupportInfo={opening.supportName && opening.supportEmail && opening.supportPhone}
						isSuperAdminOnDifferentOrganization={opening.organizationId !== user.organizationId}
					/>
					<Box className="flex justify-between items-center mt-2">
						<Box className="flex items-center">
							<Typography variant="p">
								<i
									className="fas fa-edit icon-light text-sm mr-2 cursor-pointer"
									onClick={(e) => {
										e.stopPropagation();
										window.scrollTo(0, 0);
										if (!organizationId)
											navigate(
												`/admin/create-opening-from-jd/update/${opening?.isTechnical ? "1" : "0"}/${opening._id}`,
											);
										else {
											navigate(
												`create-opening-from-jd/update/${opening?.isTechnical ? "1" : "0"}/${opening._id}`,
											);
										}
									}}
								></i>
							</Typography>
							<Typography variant="p">
								<Tooltip title="Create a Duplicate of This Opening" arrow>
									<i
										className="fas fa-copy icon-light text-sm mr-2 cursor-pointer"
										onClick={(e) => {
											e.stopPropagation();
											setToBeDuplicateId(opening._id);
											setDuplicateDialogOpen(true);
										}}
									></i>
								</Tooltip>
							</Typography>
							<Typography variant="p">
								<i
									className="fas fa-trash icon-light text-sm mr-2 cursor-pointer"
									onClick={(e) => {
										e.stopPropagation();
										setToBeDeletedId(opening._id);
										setDeleteDialogOpen(true);
									}}
								></i>
							</Typography>

							<FormGroup>
								<Tooltip
									title={
										openingStatus[opening._id]
											? "Click to make the opening inactive."
											: "Click to make the opening active."
									}
								>
									<div
										style={{
											display: "flex",
											alignItems: "center",
										}}
									>
										<Typography
											component="span"
											className="text-xs"
											style={{ marginRight: 0 }}
										>
											Opening Status
										</Typography>
										<FormControlLabel
											control={
												<Switch
													checked={openingStatus[opening._id]}
													onChange={async () => {
														try {
															setOpeningStatusSpinner((prev) => ({
																...prev,
																[opening._id]: true,
															}));
															await updateOpeningById(opening._id, {
																status: !openingStatus[opening._id],
															});
															setOpeningStatus((prev) => ({
																...prev,
																[opening._id]: !openingStatus[opening._id],
															}));
															setOpeningStatusSpinner((prev) => ({
																...prev,
																[opening._id]: false,
															}));
														} catch (e) {
															console.log(e.message);
														}
													}}
													name="openingStatusSwitch"
												/>
											}
											label={
												openingStatusSpinner[opening._id] ? (
													<i
														id=""
														className="fas fa-circle-notch fa-spin text-xs top-0"
													></i>
												) : (
													<span
														className="text-xs"
														style={{
															color: openingStatus[opening._id]
																? "green"
																: "gray",
														}}
													>
														{openingStatus[opening._id] ? "Active" : "Inactive"}
													</span>
												)
											}
											labelPlacement="end"
											style={{
												marginLeft: 0,
												marginRight: "auto",
											}}
										/>
									</div>
								</Tooltip>
							</FormGroup>
						</Box>
					</Box>
				</Box>
			</Box>
		);
	};

	const memoizedOpenings = useMemo(() => {
		return openings.filter((opening) =>
			opening.title.toLowerCase().trim().includes(searchOpening.trim().toLowerCase()),
		);
	}, [searchOpening, openings]);

	return (
		<ThemeProvider theme={theme}>
			<Box className="bg-gray-100 min-h-screen">
				<Modal
					open={showRestrictedModal}
					onClose={() => setShowRestrictedModal(false)}
					aria-labelledby="restricted-dialog-title"
					aria-describedby="restricted-dialog-description"
				>
					<Box sx={modalStyle} className="flex flex-col space-y-4 p-6">
						<Typography variant="subtitle1" className="text-lg">
							Account Restricted
						</Typography>
						<Typography variant="subtitle2" className="text-sm text-gray-500">
							{restrictedModalText}
						</Typography>
						<Box className="flex justify-end space-x-2 mt-4">
							<Button
								className="normal-case bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:border-none text-xs"
								onClick={() => setShowRestrictedModal(false)}
							>
								OK
							</Button>
						</Box>
					</Box>
				</Modal>
				<Box className="container mx-auto p-6">
					<Box className="flex justify-between mb-4">
						{
							<Typography
								className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 text-sm cursor-pointer"
								onClick={() => {
									window.scrollTo(0, 0);
									if (restricted) {
										setShowRestrictedModal(true);
										setRestrictedModalText(user.canConductInterviewMessage);
									} else {
										navigate("choose-opening-type");
									}
								}}
							>
								Create Opening
							</Typography>
						}
						<Box className="relative">
							<input
								className="border rounded-md p-2 pl-10 w-full"
								type="search"
								name="opening"
								ref={searchOpeningRef}
								id="opening-name-search"
								placeholder={`Search Opening`}
								onChange={(e) => setSearchOpening(e.target.value)}
							/>
							<i className="fas fa-search absolute top-1.5 left-3 text-gray-400 text-sm"></i>
						</Box>
					</Box>

					<Typography variant="h2" className="text-2xl font-semibold mb-4">
						All Openings
					</Typography>
					{isFetchingOpenings ? (
						<Box className={`flex ${isMobile ? "flex-col" : "flex-row"}`}>
							<ContentLoader
								speed={1.5}
								width={350}
								height={400}
								viewBox="0 0 350 400"
								backgroundColor="#e2e8f0" // gray-200
								foregroundColor="#cbd5e0" // gray-300
							>
								{/* Header and Star Icon */}
								<rect x="20" y="20" rx="4" ry="4" width="250" height="24" />
								<circle cx="320" cy="32" r="12" />

								{/* Job ID */}
								<rect x="20" y="60" rx="4" ry="4" width="150" height="16" />

								{/* Job Title */}
								<rect x="20" y="90" rx="4" ry="4" width="250" height="24" />

								{/* Experience Level */}
								<rect x="20" y="130" rx="4" ry="4" width="200" height="16" />

								{/* Created On */}
								<rect x="20" y="160" rx="4" ry="4" width="150" height="16" />

								{/* Special Instructions */}
								<rect x="20" y="200" rx="4" ry="4" width="250" height="16" />
								<rect x="20" y="230" rx="4" ry="4" width="200" height="16" />

								{/* Skills */}
								<rect x="20" y="270" rx="4" ry="4" width="250" height="16" />
								{/* <rect x="20" y="300" rx="4" ry="4" width="200" height="16" /> */}

								{/* Interview URL */}
								{/* <rect x="20" y="340" rx="4" ry="4" width="150" height="16" /> */}
								<rect x="20" y="370" rx="4" ry="4" width="200" height="24" />
								<circle cx="320" cy="382" r="12" />
							</ContentLoader>
							<ContentLoader
								speed={1.5}
								width={350}
								height={400}
								viewBox="0 0 350 400"
								backgroundColor="#e2e8f0" // gray-200
								foregroundColor="#cbd5e0" // gray-300
							>
								{/* Header and Star Icon */}
								<rect x="20" y="20" rx="4" ry="4" width="250" height="24" />
								<circle cx="320" cy="32" r="12" />

								{/* Job ID */}
								<rect x="20" y="60" rx="4" ry="4" width="150" height="16" />

								{/* Job Title */}
								<rect x="20" y="90" rx="4" ry="4" width="250" height="24" />

								{/* Experience Level */}
								<rect x="20" y="130" rx="4" ry="4" width="200" height="16" />

								{/* Created On */}
								<rect x="20" y="160" rx="4" ry="4" width="150" height="16" />

								{/* Special Instructions */}
								<rect x="20" y="200" rx="4" ry="4" width="250" height="16" />
								<rect x="20" y="230" rx="4" ry="4" width="200" height="16" />

								{/* Skills */}
								<rect x="20" y="270" rx="4" ry="4" width="250" height="16" />
								{/* <rect x="20" y="300" rx="4" ry="4" width="200" height="16" /> */}

								{/* Interview URL */}
								{/* <rect x="20" y="340" rx="4" ry="4" width="150" height="16" /> */}
								<rect x="20" y="370" rx="4" ry="4" width="200" height="24" />
								<circle cx="320" cy="382" r="12" />
							</ContentLoader>
						</Box>
					) : openings && openings.length === 0 ? (
						<p className="text-center text-zinc-400">
							No openings created yet. Create an opening and get started.
						</p>
					) : (
						<Box className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
							{memoizedOpenings.map((opening, index) => {
								return <OpeningCards opening={opening} index={index} key={opening._id} />;
							})}
						</Box>
					)}
				</Box>
				<Dialog
					open={deleteDialogOpen}
					onClose={handleDeleteDialogclose}
					aria-labelledby="delete-dialog-title"
					aria-describedby="delete-dialog-description"
				>
					<DialogTitle id="delete-dialog-title">
						{`Are you sure you want to delete the opening with the id: ${toBeDeletedId}?`}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="delete-dialog-description">
							You cannot undo this action. Once deleted, the opening details will be lost
							forever.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleDeleteDialogclose} disabled={isDeleting}>
							No
						</Button>
						<Button onClick={handleDeleteOpeningBtnClick} disabled={isDeleting}>
							Yes
						</Button>
					</DialogActions>
					{isDeleting && <LinearProgress />}
				</Dialog>
				<Dialog
					open={duplicateDialogOpen}
					onClose={handleDuplicateDialogClose}
					aria-labelledby="duplicate-dialog-title"
					aria-describedby="duplicate-dialog-description"
				>
					<DialogTitle id="duplicate-dialog-title">
						{`Are you sure you want to duplicate the opening with ID: ${toBeDuplicateId}?`}
					</DialogTitle>
					<DialogActions>
						<Button onClick={handleDuplicateDialogClose} disabled={isDuplicating}>
							No
						</Button>
						<Button onClick={handleDuplicateOpeningBtnClick} disabled={isDuplicating}>
							Yes
						</Button>
					</DialogActions>
					{isDuplicating && <LinearProgress />}
				</Dialog>
			</Box>
		</ThemeProvider>
	);
}

export default AdminPanel;

const RenderCoreSkills = ({ coreSkills, colorSchemes }) => {
	const colorClass = colorSchemes[coreSkills.length % colorSchemes.length];

	return (
		<>
			<SingleGroup
				label={"Core Skills"}
				items={coreSkills}
				colorClass={colorClass}
				isCoreSkills={true}
			/>
		</>
	);
};

const RenderSkillGroup = ({ skillsGroup, colorSchemes }) => {
	return (
		<Box>
			{skillsGroup &&
				Array.isArray(skillsGroup) &&
				skillsGroup.length > 0 &&
				skillsGroup.map((group, index) => {
					const { skillGroupName, skills } = group;
					const colorClass = colorSchemes[index % colorSchemes.length];
					return (
						<>
							<SingleGroup
								key={`${skillGroupName}.${index}`}
								label={skillGroupName}
								items={skills}
								colorClass={colorClass}
							/>
						</>
					);
				})}
		</Box>
	);
};

const RenderSpecialInstructions = ({ specialInstructions }) => {
	if (specialInstructions) {
		return (
			<Box className="my-4 flex h-[190px] w-[50ch] flex-col items-center justify-start overflow-y-auto p-3 px-2 py-4 shadow-lg">
				<h5 className="text-lg font-semibold">Special Instructions</h5>
				<p className="whitespace-pre-line p-4 text-base font-normal">{specialInstructions}</p>
			</Box>
		);
	}
};

const SingleChip = ({ colorClass = "", label }) => {
	const [showToolTip, setShowToolTip] = useState(false);
	const textElementRef = useRef(null);
	useEffect(() => {
		function handleShowTooltip() {
			setShowToolTip(textElementRef.current?.scrollWidth > textElementRef.current?.clientWidth);
		}
		handleShowTooltip();
		window.addEventListener("resize", handleShowTooltip);
		return () => {
			window.removeEventListener("resize", handleShowTooltip);
		};
	}, []);
	const ToolTipComponent = showToolTip ? Tooltip : React.Fragment;
	const props = showToolTip ? { title: <>{label}</>, arrow: true } : {};
	return (
		<Box
			className={clsx(
				`rounded-full px-2 py-1 text-xs text-ellipsis overflow-hidden whitespace-nowrap`,
				colorClass,
			)}
			ref={textElementRef}
		>
			<ToolTipComponent {...props}>
				<span>{label}</span>
			</ToolTipComponent>
		</Box>
	);
};

const SingleGroup = ({ label, items, colorClass = "", isCoreSkills = false }) => {
	const [isGroupExpanded, handleToggleExpansion] = useCollapseExpand();

	return (
		<Box className="mb-2">
			<Typography
				variant="h4"
				className="mb-1 text-sm font-semibold cursor-pointer select-none flex flex-row items-stretch w-fit"
				onClick={handleToggleExpansion}
			>
				{label}&nbsp;
				<span className="skill-child">
					<KeyboardArrowDownIcon />
				</span>
			</Typography>
			<Collapse in={isGroupExpanded} timeout={"auto"} unmountOnExit>
				<Box className="flex flex-wrap gap-2">
					{items &&
						Array.isArray(items) &&
						items.length > 0 &&
						items.map((item, itemIndex) => (
							<SingleChip
								key={isCoreSkills ? item : `${item}.${itemIndex}`}
								colorClass={colorClass}
								label={item}
							/>
						))}
				</Box>
			</Collapse>
		</Box>
	);
};

const ShowCandidatesButton = memo(
	({
		openingStatus,
		interviewReportsCount = 0,
		restricted,
		setShowRestrictedModal,
		setRestrictedModalText,
		restrictedModalText,
		organizationId,
		openingId,
		navigate,
		hasSupportInfo,
		isSuperAdminOnDifferentOrganization = false,
	}) => {
		const [assignedManagerModalOpen, setAssignedManagerModalOpen] = useState(false);
		const handleAssignedManagerModalOpen = () => {
			setAssignedManagerModalOpen(true);
		};

		const handleAssignedManagerModalClose = () => {
			setAssignedManagerModalOpen(false);
		};
		const onSuccess = useCallback(() => {
			window.scrollTo(0, 0);
			if (!organizationId) {
				navigate(`/admin/evaluations/${openingId}`);
			} else {
				navigate(`evaluations/${openingId}`);
			}
		}, [organizationId, openingId, navigate]);

		return (
			<>
				<Box className={`show-applicants ${openingStatus && "mt-2"}`}>
					<Box
						className="normal-case cursor-pointer bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:border-none text-xs transition duration-300 ease-in-out"
						component={Button}
						onClick={(e) => {
							if (restricted) {
								setShowRestrictedModal(true);
								setRestrictedModalText(restrictedModalText);
							} else {
								e.stopPropagation();
								if (hasSupportInfo || isSuperAdminOnDifferentOrganization) {
									onSuccess();
								} else {
									handleAssignedManagerModalOpen();
								}
							}
						}}
					>
						Show Candidates ({interviewReportsCount})<i className="fas fa-chevron-right ml-2"></i>
					</Box>
				</Box>
				{assignedManagerModalOpen && (
					<AssignedManager
						openingId={openingId}
						open={assignedManagerModalOpen}
						handleClose={handleAssignedManagerModalClose}
						onSuccess={onSuccess}
					/>
				)}
			</>
		);
	},
);
