import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { selectAllCandidate } from "../../app/actions/candidates/candidate";
import useGetColumnsAndRenderers from "./getColumnsAndRenderers";
import { useGridApi } from "../../app/contexts/GridApiProvider";
import { onGridFilterModalChange } from "../../app/actions/filter/filter";
// import { getFilteredRecords } from "../Filter/getFilteredRecords";
import { getFilteredRecordsFromCriteria } from "../Filter/getFilteredRecordsFromCriteria";

const ReportsDataGrid = () => {
	const ref = React.useRef(null);
	const { setGridApi } = useGridApi();

	const { candidates, showHiddenCandidates, showCompletedInterviews } = useSelector(
		(state) => state.candidates,
	);
	const { selectedOpening: openingData } = useSelector((state) => state.openingDetails);
	const { isFilterApplied, criteria } = useSelector((state) => state.filters);

	const dispatch = useDispatch();

	const onSelectionChange = (selection) => {
		dispatch(selectAllCandidate(selection));
	};

	const onFilterModelChange = (model) => {
		dispatch(onGridFilterModalChange(model.items));
	};

	const columns = useGetColumnsAndRenderers();

	const memoisedReport = React.useMemo(() => {
		let filteredReports = candidates;
		filteredReports = filteredReports.filter((el) => !!el.hidden === showHiddenCandidates);
		if (showCompletedInterviews) {
			filteredReports = filteredReports.filter(
				(report) => report.interviewCompleted && !report.clickedExitInterview,
			);
		}
		if (isFilterApplied) {
			filteredReports = getFilteredRecordsFromCriteria(filteredReports, criteria, openingData);
		}

		return filteredReports;
	}, [candidates, showHiddenCandidates, showCompletedInterviews, isFilterApplied, criteria, openingData]);

	React.useEffect(() => {
		if (ref.current) {
			setGridApi(ref.current);
		}
	}, [ref, setGridApi]);

	return (
		<div style={{ height: "85vh", width: "100%" }}>
			<DataGrid
				apiRef={ref}
				rows={memoisedReport}
				getRowId={(row) => row._id}
				columns={columns}
				checkboxSelection
				disableRowSelectionOnClick
				rowHeight={100}
				getRowClassName={() => "hoverRow"}
				onRowSelectionModelChange={onSelectionChange}
				onFilterModelChange={onFilterModelChange}
				// slots={{
				// 	toolbar: GridToolbar,
				// }}
				// filterMode="client"
				sx={{
					"& .MuiDataGrid-cell:focus": {
						outline: "none",
					},
					"& .MuiDataGrid-columnHeader:focus": {
						outline: "none",
					},
				}}
				slotProps={{
					toolbar: {
						csvOptions: {
							fields: [
								"createdAt",
								"firstName",
								"score",
								"trustScore",
								"interviewStatus",
								"resumeFileNameInS3",
								"concatenationId",
							],
						},
					},
					filterPanel: {
						filterFormProps: {
							logicOperatorInputProps: {
								variant: "outlined",
								size: "small",
							},
							columnInputProps: {
								variant: "outlined",
								size: "small",
								sx: { mt: "auto" },
							},
							operatorInputProps: {
								variant: "outlined",
								size: "small",
								sx: { mt: "auto" },
							},
							valueInputProps: {
								InputComponentProps: {
									variant: "outlined",
									size: "small",
								},
							},
							deleteIconProps: {
								sx: {
									"& .MuiSvgIcon-root": { color: "#d32f2f" },
								},
							},
						},
						sx: {
							"& .MuiDataGrid-filterForm": { p: 2 },
							"& .MuiDataGrid-filterForm:nth-child(even)": {
								backgroundColor: (theme) =>
									theme.palette.mode === "dark" ? "#444" : "#f5f5f5",
							},
							"& .MuiDataGrid-filterFormLogicOperatorInput": { mr: 2 },
							"& .MuiDataGrid-filterFormColumnInput": { mr: 2, width: 150 },
							"& .MuiDataGrid-filterFormOperatorInput": { mr: 2 },
							"& .MuiDataGrid-filterFormValueInput": { width: 200 },
						},
					},
				}}
			/>
		</div>
	);
};

export default ReportsDataGrid;
