import React, { useState, useEffect } from "react";
import "./TabularOpeningEvaluations.css";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Collapse, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import OpeningDataLoaderForEvaluations from "../components/Skeleton-Loaders/OpeningDataLoaderForEvaluations";
import ReportsLoader from "../components/Skeleton-Loaders/ReportsLoader";
import {
	hideHiddenCandidates,
	showHiddenCandidates as showHiddenCandidatesAction,
	showCompletedInterviews,
} from "../app/actions/candidates/candidate";
import { getOpeningDataWithId, setError } from "../features/createOpening/createOpeningSlice";
import ReportsDataGrid from "../components/grid/ReportsDataGrid";
import FilterComponent from "../components/Filter/FilterComponent";
import { removeAllFilters } from "../app/actions/filter/filter";
import ToolBar from "../components/TabularOpeningEvaluationsComponents/ToolBar";
import { fetchingInterviewReportWithID } from "../app/actions/candidates/candidate";

const TabularOpeningEvaluations = () => {
	const navigate = useNavigate();
	const { user } = useSelector((state) => state.auth);
	const {
		showHiddenCandidates,
		isFetchingCandidates: loadingReports,
		showCompletedInterviews: hasInterviewCompleted,
	} = useSelector((state) => state.candidates);
	const dispatch = useDispatch();
	const params = useParams();
	const {
		selectedOpening: openingData,
		loading: fetchingOpeningData,
		error,
	} = useSelector((state) => state.openingDetails);
	const [copied, setCopied] = useState(false);
	const [openForCard, setOpenForCard] = useState(false);
	const [restricted, setRestricted] = useState(false);

	async function getOpeningById() {
		try {
			if (params.openingId) {
				const data = await dispatch(getOpeningDataWithId(params.openingId)).unwrap();
				if (data && user && data?.organizationId !== user?.organizationId && user.role !== "1096") {
					throw new Error("Unauthorized");
				}
				if (user) {
					setRestricted(user?.restricted);
				}
				dispatch(removeAllFilters());
			}
		} catch (e) {
			console.log(e);
		}
	}

	async function getInterviewReportsForOpening() {
		try {
			if (params.openingId) {
				dispatch(fetchingInterviewReportWithID(params.openingId));
			}
		} catch (e) {
			console.log(e);
		}
	}
	async function fetchRequirements() {
		await getOpeningById();
		await getInterviewReportsForOpening();
	}

	useEffect(() => {
		fetchRequirements();
	}, []);

	useEffect(() => {
		if (error) {
			toast.error(`Error: ${error || "An error occurred."}`);
			setTimeout(() => {
				dispatch(setError(null));
			}, 1000);
		}
	}, [error]);

	async function handleCopyInterviewLink(openingId, shareLink) {
		try {
			if (shareLink) {
				await navigator.clipboard.writeText(shareLink);
			} else {
				await navigator.clipboard.writeText(`${window.origin}/interview/${openingId}`);
			}

			!shareLink && setCopied(true);
			toast.success("Interview link copied to clipboard");
		} catch (e) {
			console.log(e.message);
		}
	}

	useEffect(() => {
		const timeout = setTimeout(() => {
			setCopied(false);
		}, 3000);
		return () => {
			clearTimeout(timeout);
		};
	}, [copied]);

	return restricted ? (
		<Box
			className={`flex flex-col items-center justify-center bg-gray-100`}
			style={{
				height: "calc(100vh - var(--navbar-height))",
			}}
		>
			<Box id="panel2" className="bg-white p-6 rounded-lg shadow-lg w-4/5 md:w-1/2 lg:w-1/2">
				<Typography
					variant="h4"
					className={`text-center font-bold mb-6 ${isMobile ? "text-2xl" : "text-4xl"}`}
				>
					Account Restricted
				</Typography>
				<Typography
					variant="h6"
					className={`text-justify font-medium ${isMobile ? "text-sm" : "text-lg"}`}
				>
					{user.canConductInterviewMessage}
				</Typography>
			</Box>
		</Box>
	) : (
		<Box className="bg-gray-100 min-h-screen">
			<Box className="container mx-auto p-6 mt-0">
				<Box
					onClick={() => navigate(-1)}
					className="text-blue-500 text-sm hover:underline cursor-pointer"
				>
					<i className="fas fa-arrow-left text-sm mr-2"></i>Back
				</Box>
			</Box>
			<Box className="container mx-auto p-6">
				{openingData ? (
					<Box className="bg-white p-6 rounded-lg shadow-md mb-6">
						<Typography
							variant="p"
							href="opening-details.html"
							className="text-gray-500 font-bold text-xs mb-2 cursor-pointer"
							onClick={(e) => {
								setOpenForCard(!openForCard);
							}}
						>
							Job ID: {openingData?._id}
						</Typography>
						<Typography
							variant="h2"
							className="text-2xl font-semibold mb-4 cursor-pointer"
							onClick={(e) => {
								setOpenForCard(!openForCard);
							}}
						>
							{openingData?.title}
						</Typography>
						<Typography variant="p" className="text-gray-600 font-semibold mb-2 text-xs">
							Experience Level:{" "}
							{"minExperience" in openingData && "maxExperience" in openingData
								? `${openingData?.minExperience}-${openingData?.maxExperience} years`
								: `${openingData?.experience} years`}
						</Typography>

						<Box className="text-gray-500 text-xs mb-2">
							{`Created on: ${new Date(openingData?.createdAt).toLocaleString("default", {
								month: "short",
							})} ${new Date(openingData?.createdAt).getDate()}, ${new Date(
								openingData?.createdAt,
							).getFullYear()}`}
						</Box>
						<Box className="flex flex-wrap gap-4 mb-2">
							<Collapse in={openForCard} timeout={"auto"} unmountOnExit>
								<Box className="flex flex-row justify-start items-center gap-4">
									<RenderSpecialInstructions
										specialInstructions={openingData?.interviewGuidelines}
									/>
								</Box>
							</Collapse>
							{!openingData.isTechnical && openingData?.coreSkills?.length > 0 ? (
								<RenderCoreSkills
									coreSkills={openingData.coreSkills}
									colorSchemes={openingData?.colorSchemes}
								/>
							) : (
								<RenderSkillGroup
									skillsGroup={openingData?.skillsGroup}
									colorSchemes={openingData?.colorSchemes}
								/>
							)}
						</Box>
						<Box className="flex justify-between items-center">
							<Box className="w-3/4">
								<input
									type="text"
									className="border rounded-md p-2 w-full bg-gray-200 text-sm"
									value={`${window.location.protocol}//${window.location.host}/interview/${openingData?._id}`}
									disabled
								/>
								{copied ? (
									<i className="fas fa-check text-green-500 text-sm ml-2"></i>
								) : (
									<i
										className="fas fa-copy text-gray-500 text-sm cursor-pointer ml-2"
										onClick={(e) => {
											e.stopPropagation();
											handleCopyInterviewLink(openingData?._id);
										}}
									></i>
								)}
								<p className="text-gray-500 text-xs mt-2">
									Copy and share this link with the candidate.
								</p>
							</Box>
						</Box>
					</Box>
				) : (
					<OpeningDataLoaderForEvaluations />
				)}
				{loadingReports || fetchingOpeningData ? (
					<ReportsLoader />
				) : (
					<Box className="bg-white p-6 rounded-lg shadow-md mb-6">
						<ToolBar />
						<Box className="bg-gray-50 p-4 flex justify-start gap-5 items-center rounded-lg mb-6">
							<label className="flex items-center space-x-3">
								<input
									checked={hasInterviewCompleted}
									type="checkbox"
									id="showCompletedInterviews"
									onChange={(e) => {
										dispatch(showCompletedInterviews());
									}}
								/>
								<span className="text-sm">Show Completed Interviews</span>
							</label>
							<label className="flex items-center space-x-3">
								<input
									checked={showHiddenCandidates}
									type="checkbox"
									id="showHiddenCandidates"
									onChange={(e) => {
										if (!e.target.checked) {
											dispatch(hideHiddenCandidates());
										} else {
											dispatch(showHiddenCandidatesAction());
										}
									}}
								/>
								<span className="text-sm">Show Hidden Candidates</span>
							</label>
							<div className="flex flex-1 justify-end">
								<FilterComponent />
							</div>
						</Box>
						{!fetchingOpeningData && <ReportsDataGrid />}
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default TabularOpeningEvaluations;

const RenderCoreSkills = ({ coreSkills, colorSchemes }) => {
	const colorClass = colorSchemes?.[coreSkills?.length % colorSchemes?.length || 0];

	return (
		<Box className="mb-2">
			<Typography variant="h4" className="mb-1 text-sm font-semibold">
				Core Skills
			</Typography>
			<Box className="flex flex-wrap gap-2">
				{coreSkills &&
					coreSkills?.length > 0 &&
					coreSkills.map((skill, skillIndex) => (
						<Box className={`${colorClass} rounded-full px-2 py-1 text-xs`} key={skillIndex}>
							{skill}
						</Box>
					))}
			</Box>
		</Box>
	);
};

const RenderSkillGroup = ({ skillsGroup, colorSchemes }) => {
	return (
		<Box>
			{skillsGroup &&
				Array.isArray(skillsGroup) &&
				skillsGroup?.length > 0 &&
				skillsGroup?.map((group, index) => {
					const { skillGroupName, skills } = group;
					const colorClass = colorSchemes?.[index % colorSchemes.length];
					return (
						<Box key={skillGroupName + index} className="mb-2">
							<Typography variant="h4" className="mb-1 text-sm font-semibold">
								{skillGroupName}
							</Typography>
							<Box className="flex flex-wrap gap-2">
								{skills &&
									Array.isArray(skills) &&
									skills.length > 0 &&
									skills.map((skill, skillIndex) => (
										<Box
											className={`${colorClass} rounded-full px-2 py-1 text-sm`}
											key={skill + skillIndex}
										>
											{skill}
										</Box>
									))}
							</Box>
						</Box>
					);
				})}
		</Box>
	);
};

const RenderSpecialInstructions = ({ specialInstructions }) => {
	if (specialInstructions) {
		return (
			<Box className="my-4 flex h-[190px] w-[50ch] flex-col items-center justify-start overflow-y-auto p-3 px-2 py-4 shadow-lg">
				<h5 className="text-lg font-semibold">Special Instructions</h5>
				<p className="whitespace-pre-line p-4 text-base font-normal">{specialInstructions}</p>
			</Box>
		);
	}
};
