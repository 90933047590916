import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
	onGoingSearchInputVlaue: "",
};

const onGoingInterviewSlice = createSlice({
	name: "onGoingInterview",
	initialState,
	reducers: {
		updateInputValue: (state, action) => {
			state.onGoingSearchInputVlaue = action.payload;
		},
	},
});

export const onGoingInterviewApiSlice = createApi({
	reducerPath: "onGoingInterviewApi",
	baseQuery: fetchBaseQuery({
		baseUrl: "/api",
	}),
	tagTypes: ["Ongoing-Interview"],
	endpoints: (builder) => ({
		getOngoingInterviews: builder.query({
			query: () => "/interviewReports/onGoing",
			providesTags: ["Ongoing-Interview"],
		}),
	}),
});

export const { useGetOngoingInterviewsQuery } = onGoingInterviewApiSlice;
export const { updateInputValue } = onGoingInterviewSlice.actions;
export const onGoingInterviewReducer = onGoingInterviewSlice.reducer;
