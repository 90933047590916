import { configureStore } from "@reduxjs/toolkit";
import authReducer, { logoutUser } from "../features/auth/authSlice";
import organizationReducer from "../features/organization/organizationSlice";
import createOpeningReducer from "../features/createOpening/createOpeningSlice";
import { apiSlice } from "../features/api/apiSlice";
import { isRejectedWithValue } from "@reduxjs/toolkit";
import candidatesReducer from "./reducers/candidates";
import filterReducer from "./reducers/filter";
import { onGoingInterviewApiSlice, onGoingInterviewReducer } from "../features/api/onGoingReportSlice";

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
	if (isRejectedWithValue(action)) {
		const status = action?.payload?.status;
		if (status === 401) {
			store.dispatch(logoutUser());
		}
	}

	return next(action);
};

const store = configureStore({
	reducer: {
		auth: authReducer,
		organization: organizationReducer,
		openingDetails: createOpeningReducer,
		[apiSlice.reducerPath]: apiSlice.reducer,
		[onGoingInterviewApiSlice.reducerPath]: onGoingInterviewApiSlice.reducer,
		candidates: candidatesReducer,
		filters: filterReducer,
		onGoingInterview: onGoingInterviewReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.concat(apiSlice.middleware)
			.concat(onGoingInterviewApiSlice.middleware)
			.concat(rtkQueryErrorLogger),
});

export default store;
