import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import VideoTiles from "../components/videoTiles/VideoTiles";
import { useParams } from "react-router-dom";
import MeetingLobby from "../components/videoTiles/Proctor/MeetingLobby";
import { fetchInterviewReportById } from "../utilities/interviewReportsApi";
import { fetchOpeningById } from "../utilities/openingsApi";
import { clientLogger as clientLoggerApi } from "../utilities/loggingApi";

const Proctor = () => {
	const params = useParams();
	const [existingMeetingId, setExistingMeetingId] = useState(null);
	const [isMeetingJoined, setIsMeetingJoined] = useState(false);
	const [cameraEnabled, setCameraEnabled] = useState(false);
	const [micEnabled, setMicEnabled] = useState(false);
	const [interviewReportData, setInterviewReportData] = useState(null);
	const [candidateName, setCandidateName] = useState(null);
	const [openingTitle, setOpeningTitle] = useState(null);
	const [meetingEventPOSTLogger, setMeetingEventPOSTLogger] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const [shouldAddPositionSwitch, setShouldAddPositionSwitch] = useState(false);
	const [lastPositionStatus, setLastPositionStatus] = useState(false);

	const [proctorName, setProctorName] = useState("");

	function clientLogger(message, data = {}) {
		try {
			const clientLoggerData = {
				logStreamName: params?.interviewReportId,
				componentInClient: "proctor",
				...data,
			};
			clientLoggerApi(message, clientLoggerData);
		} catch (e) {
			console.log("error in proctor clientlogger definition");
			console.log(e.message);
		}
	}

	useEffect(() => {
		async function getOpeningById(openingId) {
			try {
				const data = await fetchOpeningById(openingId);
				if (data?.isSecondaryCameraRequired) setShouldAddPositionSwitch(true);
				if (data?.title) return data?.title;
			} catch (e) {
				console.log(`Error during fetching opening: ${e}`);
			}
		}

		async function showEvaluationDetails() {
			if (params.interviewReportId) {
				const report = await fetchInterviewReportById(params.interviewReportId);
				if (report) {
					setInterviewReportData(report);
					!report?.isLatestMeetingIdInvalid && setExistingMeetingId(report?.meetingId);
					report?.firstName &&
						report?.lastName &&
						setCandidateName(report?.firstName + " " + report?.lastName);
					report?.isPositionAccurate && setLastPositionStatus(true);
					report?.opening && setOpeningTitle(await getOpeningById(report?.opening));
				}
			}
		}

		async function fetchRequiredDetails() {
			try {
				await showEvaluationDetails();
			} catch (e) {
				console.log(`Error during fetching interviewReportData in Proctor: ${e}`);
			}
		}
		fetchRequiredDetails();

		const savedProctorName = localStorage.getItem("proctorName");
		if (savedProctorName) {
			setProctorName(savedProctorName);
		}
	}, []);

	useEffect(() => {
		const handleBeforeUnload = (e) => {
			// e.preventDefault();
			setExistingMeetingId(null);
			// setIsMeetingJoined(false);
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, []);

	const handleJoinMeeting = (cameraOn, micOn, name) => {
		setCameraEnabled(cameraOn);
		setMicEnabled(micOn);
		setIsMeetingJoined(true);
		const cleanedName = name.split("|")[0];
		if (cleanedName.trim() !== "") {
			localStorage.setItem("proctorName", cleanedName);
			setProctorName(name);
		}
	};

	const generatePreferredName = () => {
		const now = new Date();
		const uniqueName = `Admin_${now.getFullYear()}/${String(now.getMonth() + 1).padStart(
			2,
			"0",
		)}/${String(now.getDate()).padStart(2, "0")}_${String(now.getHours()).padStart(
			2,
			"0",
		)}${String(now.getMinutes()).padStart(2, "0")}${String(now.getSeconds()).padStart(2, "0")}`;
		return uniqueName;
	};

	function generateRandomString(length) {
		const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		let result = "|";
		const charactersLength = characters.length;
		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}

	useEffect(() => {
		if (refresh) {
			window.location.reload();
			setRefresh(false);
		}
	}, [refresh]);

	return (
		<Box
			className="container mx-auto p-4"
			style={{
				width: "min(100% - 1rem , 1200px)",
				// width: "100%",
				marginInline: "auto",
				marginBlock: "2rem",
			}}
		>
			{!isMeetingJoined && (
				<MeetingLobby
					onJoinClick={handleJoinMeeting}
					isMeetingActive={!interviewReportData?.isLatestMeetingIdInvalid}
					candidateName={candidateName}
					openingTitle={openingTitle}
					proctorName={proctorName}
				/>
			)}
			{isMeetingJoined && (
				<Box className="flex flex-col items-center justify-center">
					<VideoTiles
						vidH={"320px"}
						vidW={"400px"}
						existingMeetingId={existingMeetingId}
						preferredName="Admin"
						uniqueExtId={proctorName + generateRandomString(5)}
						cameraEnabled={cameraEnabled}
						micEnabled={micEnabled}
						candidateName={candidateName}
						openingTitle={openingTitle}
						meetingEventPOSTLogger={meetingEventPOSTLogger}
						setMeetingEventPOSTLogger={setMeetingEventPOSTLogger}
						refresh={refresh}
						setRefresh={setRefresh}
						clientLogger={clientLogger}
						lastPositionStatus={lastPositionStatus}
						shouldAddPositionSwitch={shouldAddPositionSwitch}
					/>
				</Box>
			)}
		</Box>
	);
};

export default Proctor;
