import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography, CircularProgress, Dialog, Button, DialogContent, DialogTitle } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizationName } from "../../features/organization/organizationSlice";
import { updateWelcomePageStatus } from "../../utilities/interviewReportsApi";
import { toast } from "react-toastify";

const Welcome = ({ report, opening }) => {
	const dispatch = useDispatch();
	const params = useParams();
	const [orgName, setOrgName] = useState(null);
	const { organization } = useSelector((state) => state.organization);
	const [isLoading, setIsLoading] = useState(true);
	const [showSpinner, setShowSpinner] = useState(false);
	const [aboutUs, setAboutUs] = useState("");
	const [isTandCModalOpen, setIsTandCModalOpen] = useState(false);
	const [agreedToTandC, setAgreedToTandC] = useState(false);

	useEffect(() => {
		setIsLoading(orgName ? false : true);
	}, [orgName]);

	useMemo(() => {
		if (organization?.organizationName) {
			const { organizationName = "" } = organization;
			if (organizationName) {
				setOrgName(organizationName);
				setAboutUs(organization?.aboutUs);
			}
		}
	}, [organization]);

	useEffect(() => {
		async function getOpeningDetails() {
			if (!orgName && opening?.organizationId) {
				try {
					dispatch(getOrganizationName(opening?.organizationId));
				} catch (error) {
					console.log(error);
				}
			}
		}
		getOpeningDetails();
	}, [params]);

	const renderAboutUs = () => {
		return aboutUs.split("NEWLINENEW").map((line, index) => (
			<Typography key={index} variant="p">
				{line}
				<br />
			</Typography>
		));
	};

	const handleTandCModalOpen = () => setIsTandCModalOpen(true);
	const handleTandCModalClose = () => setIsTandCModalOpen(false);

	if (isLoading) {
		return (
			<Box className="flex justify-center items-center min-h-screen">
				<CircularProgress />
			</Box>
		);
	}

	return (
		<>
			<Box className="bg-gray-200 min-h-screen pb-3">
				<Box className="flex justify-center space-x-4 mt-10">
					<Box id="step1Bar" className="bg-green-500 rounded h-2 w-24"></Box>
					<Box id="step1Bar" className="bg-gray-300 rounded h-2 w-24"></Box>
					<Box id="step2Bar" className="bg-gray-300 rounded h-2 w-24"></Box>
					{report?.createdByAdmin && (
						<Box id="step3Bar" className="bg-gray-300 rounded h-2 w-24"></Box>
					)}
				</Box>

				<Box
					id="panel_email"
					className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/2 mt-10 mx-auto text-sm"
				>
					<Typography variant="h2" className="text-center text-xl font-bold mb-6">
						Welcome to Your AI-Based Interview for the {opening?.title} Role at{" "}
						{orgName ?? "Acme Corp"}
					</Typography>

					<Box className="flex flex-col justify-between">
						<Typography variant="p" className="mt-2">
							Dear{" "}
							{report?.preferredName && report?.preferredName.length !== 0
								? report.preferredName
								: (report?.firstName && report?.firstName.length !== 0) ||
									  (report?.lastName && report?.lastName.length !== 0)
									? `${report.firstName.trim()} ${report.lastName.trim()}`
									: "Candidate"}
							,
						</Typography>
						<Typography variant="p" className="mt-2">
							Thank you for applying for the role of {opening?.title} at{" "}
							{orgName ?? "Acme Corp"}.
						</Typography>
						<Typography variant="p" className="mt-2">
							Welcome to your AI-based interview, an innovative approach combining artificial
							intelligence with traditional job applications. This technology ensures fairness
							and efficiency, eliminating unconscious bias. Prepare to engage with your virtual
							interviewer in an intuitive and engaging manner.
						</Typography>
					</Box>
					{aboutUs && (
						<Box>
							<Typography variant="h2" className="text-lg font-bold mt-2 mb-2">
								About Us
							</Typography>
							{renderAboutUs()}
						</Box>
					)}
					<Typography variant="h2" className="text-lg font-bold mb-4 mt-2">
						Interview Instructions
					</Typography>
					<Typography variant="p">
						Please familiarize yourself with the key instructions and technical requirements
						provided to ensure a smooth interview process.{" "}
					</Typography>
					<ul className="list-disc ml-6 mt-4">
						<li>
							<strong>Estimated Interview Time</strong>: Approximately 30-40 minutes.
						</li>
						<li>
							<strong>Technical Requirements</strong>: Stable internet connection, Chrome
							browser
						</li>
						<li>
							<strong>Device</strong>:{" "}
							{opening?.isMobileInterviewAllowed
								? "You can take the interview on both laptop and mobile. For the best experience, we recommend using your laptop."
								: "You can take the interview on your laptop only."}
						</li>
						<li>
							<strong>Time Limit</strong>: Provide your response to each question within a
							maximum of 7 minutes. That's more than enough time to ensure thoroughness while
							maintaining efficiency.
						</li>

						<li>
							<strong>Interview Instructions</strong>:
						</li>
						<ul className="list-disc ml-6">
							<li>
								You can ask the AI interviewer to clarify the question or take a moment to
								think about your answers.
							</li>
							<li>
								Screen And Web Cam Sharing Requirement: During the entire interview process,
								you are required to share your screen and webcam.
							</li>
						</ul>
						<li>
							<strong>Your Privacy</strong>
						</li>
						<ul className="list-disc ml-6">
							<li>
								Please note that the interview session is recorded, including your screen
								activity, audio, and video. Rest assured, we handle this information
								responsibly and in accordance with our privacy policy.
							</li>
						</ul>
					</ul>
					<Typography variant="h2" className="text-lg font-bold mb-4 mt-2">
						Navigating Technical Hiccups and Resuming Smoothly
					</Typography>
					<ul className="list-disc ml-6">
						<li>
							Should you experience any internet connectivity issues, system malfunctions, or
							other disruptions during the interview, simply refresh your browser or reopen the
							link to resume from where you left off. Rest assured, your progress is
							automatically saved, ensuring a smooth and uninterrupted continuation of your
							interview.
						</li>
					</ul>
					{opening?.allowSupportContact && (
						<ul className="list-disc ml-6">
							<li>
								If you encounter any difficulties in completing the necessary steps, please
								contact us at: {opening?.supportEmail && `${opening?.supportEmail}`}
								{opening?.supportPhone
									? `, ${opening?.supportPhone}, support@zinterview.ai.`
									: "support@zinterview.ai."}
							</li>
						</ul>
					)}
					<div className="my-4 flex gap-2 flex-row items-stretch">
						<input
							type="checkbox"
							className="scale-125 flex-shrink-0 align-middle"
							name="agree-to-tandc"
							id="agree-to-tandc"
							checked={agreedToTandC}
							onChange={(e) => {
								setAgreedToTandC(e.target.checked);
							}}
						/>
						<label className="flex-grow-[1] select-none" htmlFor="agree-to-tandc">
							I agree to the&nbsp;
							<Span handleTandCModalOpen={handleTandCModalOpen}>Terms and Conditions</Span>
						</label>
					</div>
					<Box className="flex justify-end mt-4">
						<Button
							disabled={showSpinner || !agreedToTandC}
							id="continue1"
							className={` bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none text-xs focus:border-none normal-case disabled:opacity-50 ${
								showSpinner && "cursor-not-allowed opacity-50"
							} `}
							onClick={async () => {
								if (!agreedToTandC) {
									toast.warn("Please agree to the terms and conditions!");
									return;
								}
								setShowSpinner(true);
								try {
									const resp = await updateWelcomePageStatus(report?._id);
									if (resp) {
										window.location.reload();
									} else {
										alert("Something went wrong. Please try again.");
									}
								} catch (error) {
									alert("Something went wrong. Please try again.");
									console.log(`Error in updating welcome page status: ${error}`);
								} finally {
									setTimeout(() => setShowSpinner(false), 2000);
								}
							}}
						>
							{showSpinner ? (
								<i className="fas fa-circle-notch fa-spin mr-1"></i>
							) : (
								<span>
									Continue <i className="fas fa-arrow-right mr-1"></i>
								</span>
							)}
						</Button>
					</Box>
				</Box>
			</Box>
			<Dialog
				onClose={handleTandCModalClose}
				fullWidth={true}
				maxWidth={"lg"}
				open={isTandCModalOpen}
				sx={{
					"& .MuiBackdrop-root": {
						backgroundColor: "rgba(0, 0, 0, 0.4	)",
					},
				}}
				PaperProps={{
					elevation: 1,
					sx: {
						borderRadius: "0.5rem",
						padding: "0.8rem",
					},
				}}
			>
				<DialogTitle>Terms And Conditions</DialogTitle>
				<DialogContent>
					<TermsAndConditions organizationName={orgName} report={report} />
				</DialogContent>
			</Dialog>
		</>
	);
};

export default Welcome;

const Span = ({ children, handleTandCModalOpen }) => {
	return (
		<span
			onClick={(e) => {
				e.preventDefault();
				handleTandCModalOpen();
			}}
			className="text-blue-500 cursor-pointer "
		>
			{children}
		</span>
	);
};

const TermsAndConditions = ({ report, organizationName }) => {
	const fullName = report ? report?.firstName + " " + report?.lastName : "";
	return (
		<div className="select-none">
			<p>
				As part of our commitment to using innovative tools in our recruitment process,{" "}
				{organizationName} employs AI-powered video interviewing technology. By consenting to the
				terms outlined below, you agree to participate in this process and acknowledge the associated
				rights and obligations. Please review the consent and indemnity agreement carefully.
			</p>
			<div className="flex flex-col gap-3 my-3 border-[2px] border-solid border-gray-400 rounded-sm p-1 max-h-[280px] overflow-y-auto text-sm ">
				<p>
					I, {fullName} formally acknowledge and consent to the use of Human to AI video
					interviewing technology as part of the recruitment and hiring processes conducted by&nbsp;
					{organizationName}.
				</p>
				<p>
					I, {fullName}, hereby give my full and informed consent for the Company to utilize Human
					to AI video interviewing technology in connection with my application for employment. I
					understand that this technology may involve the recording and analysis of video interviews
					and that these recordings may be used for evaluation and decision-making purposes during
					the recruitment process.
				</p>
				<p>
					In consideration of the Company's use of this technology, I agree to indemnify and hold
					harmless {organizationName}, its officers, directors, employees, agents, and affiliates,
					from and against any and all claims, demands, actions, liabilities, damages, losses,
					costs, or expenses, including reasonable attorney fees, arising out of or related to the
					use of Human to AI video interviewing technology in connection with my application for
					employment, whether such claims are current or may arise in the future. This indemnity
					applies to all claims, including but not limited to, any claims related to privacy, data
					protection, discrimination, or any other legal challenges that may be brought against the
					Company by myself or any third parties in connection with the use of this technology.
				</p>
				<p>
					I acknowledge that I have had the opportunity to seek independent legal advice before
					signing this letter and that I fully understand the implications of this indemnity.
				</p>
				I understand that this consent and indemnity does not constitute an employment contract or
				guarantee of employment. It is solely related to the Company's use of AI video interviewing
				technology as part of its recruitment process.
				<p>
					Please consider this letter as my formal consent and agreement to the terms outlined
					above.
				</p>
			</div>
		</div>
	);
};
